import React from 'react';
import Predispatch from "../../components/predispatch.component";

class FlightkeysList extends Predispatch {

    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
    }

    downloadZip = ( object ) => {
        // Download the file
        let downloadLink = document.createElement( "a" );
        downloadLink.href = object.url;
        downloadLink.download = object.name;
        downloadLink.click();
    }

    render() {
        return(
            <div className="button-group-left-align">
                { this.props.list.map( ( object, name ) => (
                    <button className="btn btn-primary btn-white-text"
                               onClick={ () => { this.downloadZip( object ) } }>Download { object.name }.zip</button>
                ))}
            </div>
        )
    }
}

export default FlightkeysList;