import React from 'react';
import { Link } from 'react-router-dom';
import Predispatch from "./predispatch.component";

class PageNotFound extends Predispatch{
    render(){
        return(
            <div className="container-fluid container-404">
                <div className="row no-gutter">
                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                    <div className="col-md-8 col-lg-6">
                        <div className="wrapper-404">
                            <div className="message-404">
                                <p className="title mb-0">404</p>
                                <p className="text">Oops, page not found.</p>
                                <div className="links">
                                    <Link to="/">Click to return home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;