import React from 'react';
import Predispatch from "./predispatch.component";


export default class Logout extends Predispatch {

    componentDidMount() {
        localStorage.clear();

        setTimeout( function() {
            window.location.replace( '/login' ); // window.location does page refresh, to remove username from header bar
        }, 2000);
    }

    render() {
        return (
            <div className="container-fluid container-login-logout">
                <div className="row no-gutter">
                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                    <div className="col-md-8 col-lg-6">
                        <div className="login d-flex align-items-center py-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9 col-lg-8 mx-auto">
                                        <div className="message-welcome">
                                            <p className="title mb-3">Back Office Portal</p>
                                            <p className="text">Successfully logged out.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}