import React from 'react';
import Predispatch from "../../components/predispatch.component";
import DateFormatter from "../../helpers/DateFormatter";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

class EurocontrolTimesList extends Predispatch {

    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
    }

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    }

    render() {
        return(
            <BootstrapTable
                // wrapperClasses="table-responsive"
                // responsive='md'
                // options={ options }
                data={ this.props.list }
                version='4'
                pagination={ false }
                search={ true }>
                <TableHeaderColumn isKey dataField='afId' dataSort={ true } hidden={ true }>AfId</TableHeaderColumn>
                <TableHeaderColumn dataField='ctot' width={ '20%' }>CTOT</TableHeaderColumn>
                <TableHeaderColumn dataField='eobt' width={ '20%' } dataFormat={ this.isoDateFormat } formatExtraData={ this }>EOBT</TableHeaderColumn>
                <TableHeaderColumn dataField='tobt' width={ '20%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>TOBT</TableHeaderColumn>
                <TableHeaderColumn dataField='tsat' width={ '20%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>TSAT</TableHeaderColumn>
                <TableHeaderColumn dataField='createdAt' width={ '20%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>Published at</TableHeaderColumn>
            </BootstrapTable>

        )
    }
}

export default EurocontrolTimesList;