import React from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Predispatch from "../../components/predispatch.component";

export default class Help extends Predispatch {

    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_GENDEC );
    }


    render() {
        return (
            <div className="container help-container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Help</h3>

                        <div className="quick-links">
                            <a href="#introduction">Introduction</a>
                            <a href="#create-example">Example for creating a message</a>
                            <a href="#search-gendec">Example for searching gendec</a>
                        </div>

                        <h3>Support</h3>
                        <p>
                            Please get in touch with us if you have any questions or feedback: <a href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>
                        </p>

                        <h3 id="introduction">Introduction</h3>
                        <p>
                            This tool can be used to create GenDec PDF's based on the input parameters. <br/>
                            <em>If you wish to print out a blank GenDec form you can simply click the generate button, without filling any of the parameters.</em>
                        </p>

                        <h3 id="create-example">Example for creating a GenDec</h3>
                        <div className="split-screen">
                            <img src={ require( '../images/create-gendec-ex-1.png' ) } alt='' />
                            <div>
                                <p>
                                    <b>Fill in the form</b> <br/>
                                    Any of the input fields are optional. <em>To create and empty GenDec, leave all input fields blank.</em>
                                </p>
                                <p>
                                    Click the 'add crew member' button to generate more input fields for the crew ( with a maximum of 18 ).
                                    All empty fields will be ignored.
                                </p>
                                <p>
                                    When all input is completed, click the 'generate gendec' button, and your PDF will open in a new tab. You can then print or download as you wish.
                                </p>
                            </div>
                        </div>

                        <h3 id="search-gendec">Example for searching gendec</h3>
                        <div className="split-screen">
                            <img src={ require( '../images/search_gendec.png' ) } alt='' />
                            <div>
                                <p>
                                    <b>Page to search flight to create gendec for</b> <br/>
                                    Default the flights of the current day are shown. If another day is needed, click on the calender and hit the Submit button.
                                </p>
                                <p>
                                    Clicking on the "Create" button, the end user will be redirected to the page where the content of the gendec can be adapted and finally the document can be created. The fields of the form will be prepopulated.
                                </p>
                            </div>
                        </div>

                        <p>Didn't find what you were looking for? You can email us at <a href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>, and we'll help you.</p>
                    </div>
                </div>
            </div>
        )
    }
}
