import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// --- Import own components/services ---
import Config from './config';
import Authenticator from '@aviation/efb-react-sdk/main/Authentication/authenticator';

//General pages
import PageNotFound from './components/pagenotfound.component';
import Login from './components/login.component';
import Logout from './components/logout.component';
import Switcher from './components/switcher.component';

//Ops Messenger pages
import OpsHomepage from './ops-messenger/components/home.component';
import OpsHelp from './ops-messenger/components/help.component';
import OpsExport from './ops-messenger/components/export.component';
import Message from './ops-messenger/components/message.component';
import Messages from './ops-messenger/components/messages.component';
import Archive from './ops-messenger/components/archivedmessages.component';

//Flight archive pages
import FaHomepage from './flight-archive/components/home.component';
import FaSearch from './flight-archive/components/search.component';
import FaFlight from './flight-archive/components/flight.component';

//Gendec pages
import GdHomepage from './gendec/components/home.component';
import GdGenerate from './gendec/components/generate.component';
import GdSearch from './gendec/components/search.component';
import GdHelp from './gendec/components/help.component';

// --- Import bootstrap/styles/jquery ---
import './components/styles/styles.scss'; // own styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'jquery/src/jquery' // needed for bootstrap-table
import 'bootstrap-table/src/bootstrap-table.scss';
import 'bootstrap-table/dist/bootstrap-table';
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.min';
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.min.css';
import Predispatch from "./components/predispatch.component";

class App extends Predispatch {

    constructor(props) {
        super(props);
        this.authenticator = new Authenticator();
    }

    async componentWillMount() {
        let user = await this.authenticator.authenticateUser( Config.getRegion(), Config.getUserPoolId() );
        this.setState( { 'user': user } );

        if( window.location.href.indexOf( 'login' ) === -1 ){
            let isValidFA = await this.authoriser.isUserAllowed( this.RESOURCE_FLIGHT_ARCHIVE, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
            let isValidOPS = await this.authoriser.isUserAllowed( this.RESOURCE_OPS_MESSENGER, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
            let isValidGD = await this.authoriser.isUserAllowed( this.RESOURCE_GENDEC, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
            if( !isValidFA && !isValidOPS && !isValidGD ) {
                this.toastMessages.toastError( 'You have no access to this resource', 'unauthorized' );
                window.location = "/login";
            }
        }
    }

    componentDidMount() {
        document.title = 'Back Office Portal';
    }

    returnLabel(){
        if( window.location.href.indexOf("/ops") !== -1 ) {
            return "Ops Messenger";
        } else if( window.location.href.indexOf("/fa") !== -1 ) {
            return "Flight Archive";
        } else if( window.location.href.indexOf("/gd") !== -1 ) {
            return "Gendec";
        }
    }

    render() {
        return (
            <Router>
                {Config.getEnvironment() !== "prod"
                    ? <header className="environment">{Config.getEnvironment().toUpperCase()}-Environment</header>
                    : ''
                }
                <div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        {window.location.href.indexOf("/ops") !== -1 || window.location.href.indexOf("/fa") !== -1 || window.location.href.indexOf("/gd") !== -1 ?
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="switcherDropdown"
                               role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.returnLabel()}
                            </a>
                            <div className="dropdown-menu" aria-labelledby="switcherDropdown">
                                <a className="dropdown-item" href="/">All portals</a>
                                <div className="dropdown-divider"></div>
                                { this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_OPS_MESSENGER, Config.getEnvironment() ) ?
                                     <a className="dropdown-item" href="/ops/home">Ops Messenger</a> //TODO: needs to be replaced
                                    // <Link to="/ops/home" className="nav-link">Ops Messenger</Link>
                                    : ''
                                }
                                { this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_FLIGHT_ARCHIVE, Config.getEnvironment() ) ?
                                     <a className="dropdown-item" href="/fa/home">Flight Archive</a>//TODO: needs to be replaced
                                    // <Link to="/fa/home" className="nav-link">Flight Archive</Link>
                                    : ''
                                }
                                { this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_GENDEC, Config.getEnvironment() ) ?
                                     <a className="dropdown-item" href="/gd/home">Gendec</a>//TODO: needs to be replaced
                                    // <Link to="/gd/home" className="nav-link">Gendec</Link>
                                    : ''
                                }
                            </div>
                        </li>
                        : ''}
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {window.location.href.indexOf("/ops") !== -1 ?
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Link to="/ops/home" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/ops/message" className="nav-link">Create message</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/ops/messages" className="nav-link">Messages</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/ops/archive" className="nav-link">Archive</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/ops/export" className="nav-link">Export PDF</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/ops/help" className="nav-link">Help</Link>
                                    </li>
                                </ul>
                            :
                            ''}
                            {window.location.href.indexOf("/fa") !== -1 ?
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Link to="/fa/home" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/fa/search" className="nav-link">Search</Link>
                                    </li>
                                </ul>
                            : ''}
                            {window.location.href.indexOf("/gd") !== -1 ?
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <Link to="/gd/home" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/gd/generate" className="nav-link">Generate gendec</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/gd/search" className="nav-link">Search</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/gd/help" className="nav-link">Help</Link>
                                    </li>
                                </ul>
                            : ''}
                            {window.location.href.indexOf("/ops") !== -1 || window.location.href.indexOf("/fa") !== -1 || window.location.href.indexOf("/gd") !== -1 ?
                            <ul className="navbar-nav mr-right">
                                {localStorage.getItem('user') ?
                                    <li className="nav-item">
                                        <Link to={'/logout'} className="nav-link">Logout</Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link to="/login" className="nav-link">Login</Link>
                                    </li>
                                }
                                <li className="nav-item nav-item-no-link">
                                    <i>{localStorage.getItem('user')}</i></li>
                            </ul>
                            : ''}
                        </div>
                    </nav>
                    {window.location.href.indexOf("/ops") === -1 && window.location.href.indexOf("/fa") === -1 && window.location.href.indexOf("/gd") === -1 ?
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <a className="navbar-brand" href="/">
                                <i className="fa fa-home"></i>
                            </a>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                </ul>
                                <ul className="navbar-nav mr-right">
                                    {localStorage.getItem('user') ?
                                        <li className="nav-item">
                                            <Link to={'/logout'} className="nav-link">Logout</Link>
                                        </li>
                                        :
                                        <li className="nav-item">
                                            <Link to="/login" className="nav-link">Login</Link>
                                        </li>
                                    }
                                    <li className="nav-item nav-item-no-link">
                                        <i>{localStorage.getItem('user')}</i></li>
                                </ul>
                            </div>
                        </nav>
                        : ''
                    }

                    {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                    <Switch>
                        {/*<UnauthenticatedRoute path="/login" component={Login} appProps={{ isAuthenticated }} />*/}
                        {/*<AuthenticatedRoute path="/message" component={Message} appProps={{ isAuthenticated }} />*/}

                        {/* Add props to track history when loading same component on different URLS */}
                        {/* https://stackoverflow.com/questions/49111353/react-router-dom-refresh-component-when-route-changes */}

                        {/* Routes for ops-messenger */}
                        <Route path="/ops/message/:uuid/:archived" component={props => <Message {...props} />}/>
                        <Route path='/ops/export' component={OpsExport}/>
                        <Route path="/ops/message" component={props => <Message {...props} />}/>
                        <Route path='/ops/messages' component={Messages}/>
                        <Route path='/ops/archive' component={Archive}/>
                        <Route path='/ops/help' component={OpsHelp}/>
                        <Route path='/ops/home' component={OpsHomepage}/>

                        {/* Routes for flight archive */}
                        <Route path='/fa/home' component={FaHomepage}/>
                        <Route path='/fa/search' component={FaSearch}/>
                        <Route path='/fa/flight/:afid' component={props => <FaFlight {...props} />}/>

                        {/* Routes for gendec */}
                        <Route path='/gd/generate/:flightid/:std' component={props => <GdGenerate {...props} />}/>
                        <Route path='/gd/home' component={GdHomepage}/>
                        <Route path='/gd/generate' component={GdGenerate}/>
                        <Route path='/gd/search' component={GdSearch}/>
                        <Route path='/gd/help' component={GdHelp}/>

                        {/* Routes for all */}
                        <Route path='/login' component={Login}/>
                        <Route exact path='/' component={Switcher}/>
                        <Route path='/logout' component={Logout}/>

                        <Route path="*" component={PageNotFound}/>
                    </Switch>
                </div>
            </Router>
        );

    }
}


export default App;
