import Config from '../../config';
import DateFormatter from "../../helpers/DateFormatter";
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';

export default class GendecFlights  {

    constructor() {
        this.apiFacade = new ApiFacade();
    }

    async getGendecFlightsByParams( params = {} ) {

        let airlines = ['JAF', 'TFL', 'TUI', 'BLX', 'TOM'];
        let flights = [];
        let flightsStr;
        let filterParams = {};

        for (let i in airlines) {
            let airline = airlines[i];
            if( params.hasOwnProperty('airlinesToFilter') && !params.airlinesToFilter.includes(airline)) {
                continue;
            }

            flightsStr = Config.getApiDomain() + '/tibco/v1/getFlights/' + airline + '/';

            filterParams = {};
            if( params.hasOwnProperty('airlinesToFilter') && params.airlinesToFilter !== null ) {
                filterParams[ 'airlinesToFilter' ] = ( params.airlinesToFilter );
            }

            if( params.hasOwnProperty('flightDate') && params.flightDate !== null && params.flightDate !== '' ) {
                // In case only startDate is selected
                flightsStr += params.flightDate;
            } else {
                // In case no date is selected
                let today = new Date();
                let formattedDate = DateFormatter.getSqlDate( today ) ;
                flightsStr += formattedDate;
            }

            flights = [].concat(flights, await this.getFlightsForGendec( flightsStr ));
        }

/*
        let flightsStr = Config.getApiDomain() + '/tibco/v1/getFlights/ALL/';

        let filterParams = {};
        if( params.hasOwnProperty('airlinesToFilter') && params.airlinesToFilter !== null ) {
            filterParams[ 'airlinesToFilter' ] = ( params.airlinesToFilter );
        }

        if( params.hasOwnProperty('flightDate') && params.flightDate !== null && params.flightDate !== '' ) {
            // In case only startDate is selected
            flightsStr += params.flightDate;
        } else {
            // In case no date is selected
            let today = new Date();
            let formattedDate = DateFormatter.getSqlDate( today ) ;
            flightsStr += formattedDate;
        }

        let flights = await this.getFlightsForGendec( flightsStr );
    */
        if( !flights ) {
            return null;
        } else {
            return this.filterFlightsByParams(filterParams, flights)
        }

    }

    filterFlightsByParams ( params = {}, flights ) {
        let filteredFlights = [];

        for ( let i in flights ) {
            let flight = flights[ i ];
            if ( params.hasOwnProperty( 'airlinesToFilter' ) && params.airlinesToFilter !== null ) {
                for ( let i in params.airlinesToFilter ) {
                    let filterAirline = params.airlinesToFilter[ i ];
                    // Check if airline is same as desired airlines and if the flight isn't already added to the list
                    if ( flight.AircraftOwner === filterAirline && !Object.keys( filteredFlights ).includes( flight.AF_ID ) ) {
                        filteredFlights[ flight.AF_ID ] = flight;
                    }
                }
            }
        }

        return Object.values( filteredFlights );
    }

    async getFlightsForGendec( flightsStr ) {
        let flightsResponse = await this.apiFacade.performGetRequest( flightsStr );
        if( flightsResponse.isSuccessful ) {
            return flightsResponse.data.ScheduledFlights;
        } else {
            return null;
        }


    }

    async callTibcoFlightDetails( flightId, std ){
        let flId = flightId;
        let airportDep = 'IGNORED';
        let date = std;
        let tibcoUrlFlightDetails = '/tibco/v1/getFlightDetails/' + flId + '/' + airportDep + '/' + date;
        let tibcoUrlFlightInformation = '/tibco/v1/getFlightInformation/' + flId;
        let allFlightInformation = [];

        let tibcoUrlFlightDetailsPromise = await this.apiFacade.performGetRequest( Config.getApiDomain() + tibcoUrlFlightDetails );
        let tibcoUrlFlightInformationPromise = await this.apiFacade.performGetRequest( Config.getApiDomain() + tibcoUrlFlightInformation );

        let promiseResponses = await Promise.all( [
            tibcoUrlFlightDetailsPromise,
            tibcoUrlFlightInformationPromise
        ] );
        for( let i in promiseResponses ) {
            let response = promiseResponses[ i ];
            if( response.isSuccessful ) {
                allFlightInformation.push( response.data );
                //this.prePopulateFormWithTibcoFlightDetails( allFlightInformation );
            } else {
                return null;
            }
        }
        return allFlightInformation;
    }

    async getGendecPersons( flightId ) {
        let gendecUrlPersons = '/gendec/v1/flights/' + flightId + '/persons';
        let gendecPersonsApiResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + gendecUrlPersons );
        if( gendecPersonsApiResponse.isSuccessful ) {
            return gendecPersonsApiResponse.data.body;
        } else {
            return null;
        }
    }

}
