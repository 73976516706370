import React from 'react';
import Config from '../../config';
import { withRouter } from "react-router";
import $ from 'jquery';

import Predispatch from "../../components/predispatch.component";
import DateFormatter from "../../helpers/DateFormatter";

class OpsMessages extends Predispatch {

    constructor( props ) {
        super( props );
        this.state = { messages: [] };
    }

    async componentDidMount() {
        this.setState( { loading: true } );
        let hasAccess = await this.validateResourceAccess( this.RESOURCE_OPS_MESSENGER );
        if( hasAccess ){
            let api_endpoint = Config.getApiDomain() + "/ops-messages/v1/messages";
            let messagesResponse = await this.apiFacade.performGetRequest( api_endpoint );
            if( messagesResponse.isSuccessful ) {
                this.processMessagesResponse( messagesResponse.data.body );
            } else {
                this.toastMessages.toastError( 'Error fetching messages.', 'opsmsg-GET' );
            }

        }
        this.setState( { loading: false } );
    }

    processMessagesResponse( retrievedMessages ){
        let messages = [];
        for( let i in retrievedMessages ) {
            let message = this.cleanUpDisplayedMessages( retrievedMessages[ i ] );
            messages.push( message );
        }
        this.initTable( messages );
    };

    // Clean up or format data before displaying in table
    cleanUpDisplayedMessages( message ){
        let today = new Date();
        let endDate = new Date( message.end );

        // Create ISO string of dates ( without hours ) to compare when to mark message as disabled or not
        let todayIso = today.toISOString().substr( 0, 10 );
        let endDateIso = endDate.toISOString().substr( 0, 10 );

        if( message.end === '9999-12-31' ){
            message.end = 'UFN';
        } else if( today > endDate && todayIso !== endDateIso ){
            message.end = 'Disabled';
        }

        if( message.title.length > 48 ){
            message.title = message.title.substring( 0, 48 ) + '..';
        }

        if( message.AOC === '' ) {
            message.AOC = 'JAF, TUI, TFL, BLX, TOM';
        }

        return message;
    }

    dateFormatter( dateString ){
        return DateFormatter.getReadableDateTimeFromIso( dateString );
    }

    linkFormatter( cell, row, index ) {
        return "<a href='/ops/message/" + row.UUID + "/active'>"+cell+"</a>";
    }

    initTable = ( messages ) => {
        let $table = $( '#table' );
        $table.bootstrapTable( 'destroy' ).bootstrapTable( {
            data: messages,
            locale: 'en-US',
            showFullscreen: true, // option to make table full screen
            filterControl: true, // allow filtering ( type of filter defined per column )
            search: true, // searchbox for entire table
            visibleSearch: true, // only search in visible/shown fields of the item
            showSearchClearButton: true, // clear button to clear the search field
            showColumns: true, // toggle to show/hide columns
            showColumnsToggleAll: true, // toggle to quickly show/hide all columns
            clickToSelect: true, // checkbox in front
            pagination: true, // pagination
            pageList: [ 10, 50, 'all' ], // show pagination options
            columns: [
                [
                    {
                        field: 'title',
                        title: 'Title',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input',
                        formatter: this.linkFormatter
                    }, {
                        field: 'AOC',
                        title: 'Airline',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input'
                    }, {
                        field: 'ARP',
                        title: 'Airport',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input'
                    }, {
                        field: 'REG',
                        title: 'Aircraft',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input'
                    }, {
                        field: 'start',
                        title: 'Start',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input'
                    }, {
                        field: 'end',
                        title: 'End',
                        sortable: true,
                        align: 'center',
                        filterControl: 'input'
                    }, {
                        field: 'creationTime',
                        title: 'Created at',
                        sortable: true,
                        align: 'center',
                        formatter: this.dateFormatter
                    }
                ]
            ]
        });
    }

    render() {
        return (
            <div className="container container-search-flights" style={{'maxWidth':'1280px'}}>
                <div className="row">
                    <div className="col-lg-12 react-bs-table-container-wrapper">
                        <h3 className="page-title mt-5 mb-5">Message list</h3>

                        { this.state.loading &&
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        }

                        {/* Table content loaded by bootstrap-table in JS */}
                        <table id="table"></table>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter( OpsMessages );