import Config from "../../config";
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';

export default class FlightIds{
    constructor(){
        this.apiFacade = new ApiFacade();
    }

    async getFlightIds() {

        let url = Config.getApiDomain()  + "/returndata/v1/flightids";
        let flightIdsResponse = await this.apiFacade.performGetRequest( url );
        if( flightIdsResponse.isSuccessful ) {
            return flightIdsResponse.data.body;
        } else {
            return null;
        }

    }

    /**
     * Filters unmapped flight IDs from all flight IDs
     */
    filterUnmappedFlightIds( flightIds ) {
        let negFlightIds = [];

        for( let i in flightIds ) {
            let flightId = flightIds[i];

            if( !flightId.idpsFlightId ) {
                negFlightIds.push( flightId );
            }
        }

        return negFlightIds;
    }

    /**
     * Maps negative flight id with idps flight id
     *
     * @param {number} negFlightId
     * @param {number} idpsFlightId
     *
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    async mapFlightId( negFlightId, idpsFlightId ) {
        let url = Config.getApiDomain()  + "/returndata/v1/flightids/"+negFlightId;
        let postData = {
            "idpsFlightId": idpsFlightId
        };

        let mapPutResponse = await this.apiFacade.performPutRequest( url, postData );
        if( mapPutResponse.isSuccessful ) {
            return true;
        } else {
            return false;
        }

    }
}