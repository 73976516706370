const config = require( './config/vars.json' );

const Config = {
    /**
     * Gets current region
     *
     * @returns {string}
     */
    getRegion: function() {
        return config.REGION;
    },

    /**
     * Gets current environment
     *
     * @return {string}
     */
    getEnvironment: function() {
        return config.ENV;
    },

    /**
     * Get the AD login client Id - AD identity provider
     *
     * @returns {string}
     */
    getAdLoginClientId: function() {
        return config.AD_LOGIN_CLIENT_ID;
    },

    /**
     * Gets user pool Id
     *
     * @returns {string}
     */
    getUserPoolId: function() {
        return config.USER_POOL_ID;
    },

    /**
     * Gets Cognito URL where the oAuth2 authentication occurs
     *
     * @returns {string}
     */
    getAuthDomain() {
        return "https://"+config.AUTH_DOMAIN;
    },

    /**
     * Gets portal URL
     *
     * @return {string}
     */
    getPortalDomain() {
        return "https://"+config.PORTAL_DOMAIN;
    },
    /**
     * Gets EFB API URL
     * The old EFB API url ( must be deprecated )
     *
     * @returns {string}
     */
    getApiDomain() {
        return "https://"+config.API_DOMAIN;
    }
}

export default Config;
