
const StreamConverter = {

    /**
     * Converts BASE64 to Binary
     *
     * @param input
     *
     * @return {Blob}
     */
    base64ToBinary( input, contentType ) {
        let byteCharacters = atob( input );
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: contentType});
    }
};

export default StreamConverter;