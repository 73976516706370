import {Component} from "react";
import Config from "../config";
import Authoriser from '@aviation/efb-react-sdk/main/Authorisation/authoriser';
import TokenHelper from '@aviation/efb-react-sdk/main/Authorisation/tokenHelper';
import ToastMessages from "@aviation/efb-react-sdk/main/helpers/toastMessages";
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';

export default class Predispatch extends Component {

    RESOURCE_OPS_MESSENGER  = "EFB_PORTAL_OPS_MESSENGER";
    RESOURCE_FLIGHT_ARCHIVE = "EFB_PORTAL_FLIGHT_ARCHIVE";
    RESOURCE_GENDEC         = "EFB_PORTAL_GENDEC";

    constructor( props ) {
        super( props );

        this.authoriser = new Authoriser();
        this.tokenHelper = new TokenHelper();
        this.toastMessages = new ToastMessages();
        this.apiFacade = new ApiFacade();

        this.state = {};
    }

    async validateResourceAccess( resource ) {
        let isValid = await this.authoriser.isUserAllowed( resource, Config.getRegion(), Config.getUserPoolId() );
        if( !isValid ) {
            this.toastMessages.toastValidation( 'Invalid user detected.', 'user' );
            return false;
        } else {
            let user = await this.tokenHelper.getCurrentUser();
            if( !user ){
                this.toastMessages.toastValidation( 'Invalid user detected.', 'user' );
                return false;
            } else {
                if( !this.authoriser.userHasAllowedRole( user, resource, Config.getEnvironment() ) ){
                    this.toastMessages.toastError( 'You have no access to this resource.', 'unauthorized' );
                    this.props.history.push( "/" );
                    return false;
                }
                return true;
            }
        }
    };
}