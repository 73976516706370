import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import Config from '../../config';
import MessageManager from '../services/MessageManager';
import DateFormatter from "../../helpers/DateFormatter";
import moment from 'moment';

import $ from "jquery";

import Calendar from 'react-calendar'; // calendar
import 'react-calendar/dist/Calendar.css';
import { confirmAlert } from 'react-confirm-alert'; // confirm dialog
import 'react-confirm-alert/src/react-confirm-alert.css';
import Predispatch from "../../components/predispatch.component";
import StreamConverter from "../../helpers/StreamConverter";
import 'react-calendar/dist/Calendar.css';
import '../../../src/components/styles/react-calendar.scss'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';

export default class Create extends Predispatch {
    constructor( props ) {
        super( props );

        this.handleStartDateSelect = this.handleStartDateSelect.bind( this );
        this.handleEndDateSelect = this.handleEndDateSelect.bind( this );
        this.handleFileUpload = this.handleFileUpload.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.validateForm = this.validateForm.bind( this );

        this.messageManager = new MessageManager();
        
        this.state = {
            isReadOnly: true,
            files: []
        };

        // This binding is necessary to make `this` work in the callback
        this.toggleReadOnly = this.toggleReadOnly.bind(this);
    }

    async componentDidMount() {
        this.setState( { loading: true } );

        let hasAccess = await this.validateResourceAccess( this.RESOURCE_OPS_MESSENGER );
        if ( hasAccess ) {
            // Set state can only be placed after components were mounted, don't use in constructor
            // Will be set to 1 if user uploads attachment
            this.setState( { 'file': "" } );

            let uuid = null;
            if ( this.props.match.params[ 'uuid' ]) {
                uuid = this.props.match.params[ 'uuid' ];
            }

            let archived = false;
            if( this.props.match.params[ 'archived' ] === 'archived' ) {
                archived = true;
            }

            if( uuid ) {
                let arrIdentifier = uuid.split( "-" );
                let countIdentifier = arrIdentifier.length;
                let identifier = arrIdentifier[ countIdentifier - 1 ];
                this.setState({
                    'identifier': identifier,
                    'UUID': uuid
                });
                if( archived ) {
                    // For archived messages
                    this.setState({
                        'archived': 1,
                        'pageTitle': 'archived'
                    } );

                    let archivedMessage = await this.messageManager.getArchivedMessageByUUID( uuid, localStorage.getItem( "access_token" ) );
                    if( !archivedMessage ) {
                        this.toastMessages.toastError( 'Error occurred when retrieving archived message from the backend.', 'opsmsg-GET' );
                    } else {
                        this.populateFields( archivedMessage );
                        this.editingNotAllowed();
                    }
                } else {
                    // For non-archived messages
                    this.setState({
                        'pageTitle': 'edit',
                        'submitLabel': 'Save message'
                    });

                    let message = await this.messageManager.getMessageByUUID( uuid, localStorage.getItem( "access_token" ) );
                    if( !message ) {
                        this.toastMessages.toastError( 'Error occurred when retrieving message from the backend.', 'opsmsg-GET' );
                    } else {
                        this.populateFields( message );
                    }
                }

                let messageHistory = await this.messageManager.getMessageHistory( uuid );
                if( !messageHistory ) {
                    this.toastMessages.toastError( 'Error occurred when retrieving message history from the backend.', 'opsmsg-GET' );
                } else {
                    this.setState( {'messageHistory': messageHistory} );
                    this.selectMessageInHistoryTable( uuid );
                }
            } else {
                this.setState( {
                    'UUID': '',
                    'pageTitle': 'create',
                    'submitLabel': 'Create message',
                    'creator': localStorage.getItem( "user" )
                } );
            }
            this.handleAllAocBox();
        }
        this.setState( { loading: false } );
    }


    // If message is archived, user is not allowed to edit
    editingNotAllowed(){
        if( document.getElementById( 'btnUpload' ) ) {
            document.getElementById( 'btnUpload' ).remove();
        }
    }

    // Populates form by JSON message from lambda
    populateFields( jsMessage ) {
        this.setState( {
            'REG': jsMessage.REG,
            'AOC': jsMessage.AOC,
            'ARP': jsMessage.ARP,
            'arr': jsMessage.arr,
            'dep': jsMessage.dep,
            'altn': jsMessage.altn,
            'CTY': jsMessage.CTY,
            'FNO': jsMessage.FNO,
            'AIRSPACES': jsMessage.AIRSPACES,
            'TYP': jsMessage.TYP,
            'start': DateFormatter.createDateFromSqlDate( jsMessage.start ),
            'title': jsMessage.title,
            'text': jsMessage.text,
        } );
        if( jsMessage.end !== "9999-12-31" ) {
            this.setState( { 'end': DateFormatter.createDateFromSqlDate( jsMessage.end )  } );
        }

        let aocs = [];
        let inputElements = document.getElementsByClassName( 'aocBoxes' );
        for( let i=0; inputElements[ i ]; ++i ){
            if( jsMessage.AOC.indexOf( inputElements[ i ].value ) !== -1 ){
                inputElements[ i ].checked = true;
                aocs.push( inputElements[ i ].value );
            }
        }

        inputElements = document.getElementsByClassName( 'arpBoxes' );

        for( let i=0; inputElements[ i ]; ++i ){
            if( jsMessage.dep && inputElements[ i ].value === "Departure" ) {
                inputElements[ i ].checked = true;
            }
            if( jsMessage.arr && inputElements[ i ].value === "Arrival" ) {
                inputElements[ i ].checked = true;
            }
            if( jsMessage.altn && inputElements[ i ].value === "Alternate" ) {
                inputElements[ i ].checked = true;
            }
        }
        
        this.setState( {
            'AOC': aocs.join(),
            'files': jsMessage.files,
            'creator': jsMessage.creator
        } );

        this.handleAllAocBox();

    }

    handleAllAocBox = ( e ) => {
        let checked = false;
        // Check if one of the single checkboxes (JAF, TUI, TFL, BLX, TOM) is checked
        // Ignore the 'ALL AOC' box
        $(".aocBoxes").each( function () {
            if ( $(this).is(':checked') && $(this).val() !== 'All AOC' ) {
                checked = true;
            }
        });
        // If no single checkbox is checked, select 'All AOC'
        if( checked === false ) {
            $("input:checkbox[value='All AOC']").prop( "checked", true );
        } else {
            $("input:checkbox[value='All AOC']").prop( "checked", false );
        }
    }

    handleAllAocEvent = ( e ) => {
        let aocs = [];
        this.setState( { 'AOC': aocs.join() } );
    }

    handleSpecificAocEvent = ( value ) => {
        let aocs = [];
        let inputElements = document.getElementsByClassName( 'aocBoxes' );
        // Loop over de single checkboxes (JAF, TUI, TFL, BLX, TOM).
        // Ignore the 'ALL AOC' box by startin with index 1
        for( let i=1; inputElements[ i ]; ++i ){
            if ( inputElements[ i ].checked ) {
                aocs.push( inputElements[ i ].value );
            }
        }
        this.setState( { 'AOC': aocs.join() } );
    }

    // --- Step 1. Select criteria
    handleAOCChange = ( e ) => { // Get all selected AOC values
        let value = e.nativeEvent.target.value;
        if( value === 'All AOC' ) {
            $(".aocBoxes").prop("checked", false); // Uncheck all AOC checkboxes
            this.handleAllAocBox();
            this.handleAllAocEvent();
        } else {
            this.handleAllAocBox();
            this.handleSpecificAocEvent( value );
        }
    }

    handleARPBoolChange = ( e ) => { // Get all selected ARPS values
        let arps = [];
        let inputElements = document.getElementsByClassName( 'arpBoxes' );
        for( let i=0; inputElements[ i ]; ++i ){
            if( inputElements[ i ].checked ){
                arps.push( inputElements[ i ].value );
            }
        }

        let arrival = null;
        let departure = null;
        let alternate = null;
        if( arps.includes( 'Arrival' ) ){
            arrival = e.target.value;
        }

        if( arps.includes( 'Departure' ) ){
            departure = e.target.value;
        }

        if( arps.includes( 'Alternate' ) ){
            alternate = e.target.value;
        }

        this.setState({
           'arr': arrival,
           'dep': departure,
           'altn': alternate
        });
    }

    handleCriteriaChange = ( e ) => {
        switch( e.target.id ) {
            case 'inputACReg':
                this.setState( { 'REG': e.target.value } );
                break;
            case 'inputACType':
                this.setState( { 'TYP': e.target.value } );
                break;
            case 'inputAirport':
                this.setState( { 'ARP': e.target.value } );
                break;
            case 'inputCountry':
                this.setState( { 'CTY': e.target.value } );
                break;
            case 'inputFlightNr':
                this.setState( { 'FNO': e.target.value } );
                break;
            case 'inputAirspaces':
                this.setState( { 'AIRSPACES': e.target.value } );
                break;
            default:
            // catch undefiend case
        }
    }

    // --- Step 2. Select start and end date
    handleStartDateSelect( date ) {
        // Store in state
        this.setState({ 'start': date } );
    }

    handleEndDateSelect( date = '' ){
        // Store in state
        this.setState( { 'end': date } );
    }

    // --- Step 3. Create ops message ( and check for optional uploaded file )
    handleMessageChange = ( e ) => {
        switch( e.target.id ) {
            case 'inputMessageTitle':
                this.setState( { 'title': e.target.value } );
                break;
            case 'inputMessageText':
                this.setState( { 'text': e.target.value } );
                break;
            default:
            // catch undefiend case
        }
    }

    handleFileUpload = e => { // Optional file/PDF upload in step 3
        let file = e.target.files[ 0 ];
        let fileType = file.type;
        let fileSize = file.size;

        if( fileType !== 'application/pdf' ){
            this.toastMessages.toastError( 'Only PDF uploads are allowed.', 'opsmsg-UPLOADING' );
            document.getElementById( 'inputUploadPdf' ).value = "";
        } else if( fileSize >= 4000000 ){ // 4 000 000 bytes = 4mb
            this.toastMessages.toastError( 'The uploaded file is to large. Maximum allowed size is 4mb.', 'opsmsg-UPLOADING' );
            document.getElementById( 'inputUploadPdf' ).value = "";
        } else {
            let reader = new FileReader();
            reader.readAsBinaryString( file );

            reader.onload = () => {
                let base64 = btoa( reader.result );
                this.setState( { 'file': base64 } );
            };
            reader.onerror = () => {
                this.toastMessages.toastError( 'Could not upload PDF.', 'opsmsg-UPLOADING' );
                document.getElementById( 'inputUploadPdf' ).value = "";
            };
        }
    };

    // --- Run after user submits form
     handleSubmit = async () => {
         this.setState( { loading: true } );
         let strStartDate = null;
         if( this.state.start ) {
             strStartDate = DateFormatter.getSqlDate( this.state.start );
         }
         let strEndDate = '9999-12-31';
         if( this.state.end ) {
             strEndDate = DateFormatter.getSqlDate( this.state.end );
         }
         // Validate the rest of the fields
         let formIsValid = this.validateForm();
         if( formIsValid ) {
             // If form is completely validated, start axios call
             
             let file = null;
             // In case a new file is uploaded
             if( this.state.file !== '' ) {
                 file = this.state.file;
             }
             // In case the message is edited and the file has not been changed
             if( this.state.files.length > 0 ) {
                 file = this.state.files[0];
             }

             let axiosData = {
                 "file": file,
                 "end": ( strEndDate ),
                 "AOC": ( this.state.AOC ? this.state.AOC : null ),
                 "REG": ( this.state.REG ? this.state.REG : null ),
                 "TYP": ( this.state.TYP ? this.state.TYP : null ),
                 "CTY": ( this.state.CTY ? this.state.CTY : null ),
                 "ARP": ( this.state.ARP ? this.state.ARP : null ),
                 "arr": ( this.state.arr ? 1 : 0 ),
                 "dep": ( this.state.dep ? 1 : 0 ),
                 "altn": ( this.state.altn ? 1 : 0 ),
                 "FNO": ( this.state.FNO ? this.state.FNO : null ),
                 "AIRSPACES": ( this.state.AIRSPACES ? this.state.AIRSPACES : null ),
                 "title": ( this.state.title ? this.state.title : null ),
                 "text": ( this.state.text ? this.state.text : null ),
                 "start": ( strStartDate ),
                 "UUID": ( this.state.UUID ),
                 "creator" : ( localStorage.getItem( "user" ) )
             };

             let messagePostResponse = await this.apiFacade.performPostRequest( Config.getApiDomain() + '/ops-messages/v1/messages', axiosData );
             if( messagePostResponse.isSuccessful ) {
                 localStorage.removeItem( 'editorHtml' ); // remove editor value from localstorage
                 if( this.state.UUID ){
                     if( this.state.archived ){
                         this.toastMessages.toastSuccess( 'Message has been archived.', 'opsmsg-ARCHIVED' );
                     } else {
                         this.toastMessages.toastSuccess( 'Message has been updated.', 'opsmsg-UPDATED' );
                     }
                 } else {
                     this.toastMessages.toastSuccess( 'Message has been published.', 'opsmsg-CREATED' );
                 }
                 let self = this;
                 setTimeout( function() {
                     if( self.state.UUID ) { // edit
                         window.location.replace( '/ops/messages' );
                     } else { // create
                         window.location.replace( '/' );
                         window.location.replace( '/ops/message/' + messagePostResponse.data.body + '/0' );
                     }
                 }, 1000);
             } else {
                 this.toastMessages.toastError( 'Error while sending data to backend service.', 'opsmsg-POST' );
             }

         } else {
             this.toastMessages.toastError( 'Some data in the form is invalid.', 'opsmsg-VALIDATING' );
         }
         this.setState( { loading: false } );
    };

    validateForm(){
        let formIsValid = true; // will be overwritten if anything is invalid
        
        let startDate       = ( this.state.start ? this.state.start : null );
        let endDate         = ( this.state.end ? this.state.end : null );
        let acReg           = ( this.state.REG ? this.state.REG.trim() : null );
        let acType          = ( this.state.TYP ? this.state.TYP.trim() : null );
        let airport         = ( this.state.ARP ? this.state.ARP.trim() : null );
        let arrival         = ( this.state.arr ? this.state.arr : null );
        let departure       = ( this.state.dep ? this.state.dep : null );
        let alternate       = ( this.state.altn ? this.state.altn : null );
        let country         = ( this.state.CTY ? this.state.CTY.trim() : null );
        let flightNr        = ( this.state.FNO ? this.state.FNO.trim() : null );
        let airspaces       = ( this.state.AIRSPACES ? this.state.AIRSPACES.trim() : null );
        let messageText     = ( this.state.text ? this.state.text.trim() : null );
        let messageTitle    = ( this.state.title ? this.state.title.trim() : null );
        let delimiter       = ",";

        // Required fields
        if( messageTitle == null || messageTitle === '' ){
            this.toastMessages.toastValidation( 'Missing a title for the ops message.', 'opsmsg-TITLE' );
            formIsValid = false;
        } else {
            if( messageTitle.length > 256 ){
                this.toastMessages.toastValidation( 'Ops message title can not be more then 256 characters.', 'opsmsg-TITLE' );
                formIsValid = false;
            }
        }

        if( startDate == null ){
            this.toastMessages.toastValidation( 'No start date was selected. End date remains optional.', 'opsmsg-DATE' );
            formIsValid = false;
        }

        // Check if dates are valid, end date can not be greater then start date
        if( endDate !== null ){
            let yesterday = new Date( ( new Date() ).valueOf() - 1000*60*60*24 );

            let yesterdayIso = yesterday.toISOString().substr( 0, 10 );
            let endDateIso = endDate.toISOString().substr( 0, 10 );

            // If endDateIso !== yesterdayIso allows for messages to be disabled
            if( endDateIso !== yesterdayIso ){
                if( startDate > endDate ){
                    this.toastMessages.toastValidation( 'The end date can not be before the start date.', 'opsmsg-DATE' );
                    formIsValid = false;
                }
            }
        }

        // Check regex for all other form fields
        if( acReg !== null ){ // REG
            let acRegs = acReg.split( delimiter ).map( i => i.trim() )
            for( let i in acRegs ) {
                acReg = acRegs[ i ]
                if( !/^[a-zA-Z]{1,5}$/.test( acReg ) ) {
                    this.toastMessages.toastValidation( 'Aircraft registration must be 1 to 5 letters. No hyphen.', 'opsmsg-ACREG' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( acType !== null ){ // TYP
            let acTypes = acType.split( delimiter ).map( i => i.trim() )
            for( let i in acTypes ) {
                let acType = acTypes[ i ]
                if( !/^[a-zA-Z0-9]{1,4}$/.test( acType ) ){
                    this.toastMessages.toastValidation( 'Aircraft type must be between 1 and 4 characters, numbers allowed.', 'opsmsg-ACTYPE' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( airport !== null ){ // ARP
            let airports = airport.split( delimiter ).map( i => i.trim() )
            for( let i in airports ) {
                let airport = airports[ i ]
                if ( !/^[a-zA-Z]{4}$/.test( airport ) ) {
                    this.toastMessages.toastValidation( 'Airport value must be 4 characters long.', 'opsmsg-AIRPORT' );
                    formIsValid = false;
                    break;
                }
            }
        } else{
            if( arrival !== null ||  departure !== null ||  alternate !== null ){ // ARR DEP ALTN
                this.toastMessages.toastValidation( 'Arrival, departure or alternate can only be checked if the airport has a value.', 'opsmsg-AIRPORT' );
                formIsValid = false;
            }
        }

        if( country !== null ){ // CTY
            let countries = country.split( delimiter ).map( i => i.trim() )
            for( let i in countries ) {
                let country = countries[ i ]
                if (!/^[a-zA-Z0-9]{1,2}$/.test( country ) ) {
                    this.toastMessages.toastValidation( 'Country value must be between 1 and 2 characters long, numbers allowed.', 'opsmsg-COUNTRY' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( flightNr !== null ){ // FNO
            let flightNrs = flightNr.split( delimiter ).map( i => i.trim() )
            for( let i in flightNrs ) {
                let flightNr = flightNrs[ i ]
                if ( !/^-{0,1}[a-zA-Z0-9]{2,12}$/.test(flightNr) || flightNr.length > 13 ) {
                    this.toastMessages.toastValidation( 'Flight number must be between 2 and 13 characters.', 'opsmsg-FLIGHTNR' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( airspaces !== null ){ // CTY
            let arrAirspaces = airspaces.split( delimiter ).map( i => i.trim() )
            for( let i in arrAirspaces ) {
                let airspaces = arrAirspaces[i]
                if ( !/^[a-zA-Z]{1,4}$/.test( airspaces ) ) {
                    this.toastMessages.toastValidation( 'Airspace value must be between 1 and 4 letters long.', 'opsmsg-AIRSPACE' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( messageText !== null ){
            if( messageText.length > 5000 ){
                this.toastMessages.toastValidation( 'Ops message can not be more then 5000 characters.', 'opsmsg-MESSAGETEXT' );
                formIsValid = false;
            }
        }

        return formIsValid;
    };

    // Download PDF on button click
    async fetchPdf( self ) {
        this.setState( { loading: true } );
        $( '#view-attachment' ).attr( 'onClick', '' ).addClass( 'disabled' ); // disable button

        let uuid = self.state.UUID;
        let fileName = self.state.files[0];
        let api_endpoint = Config.getApiDomain() + '/ops-messages/v1/messages';
        let url = api_endpoint;

        let pdfFileResponse = await this.apiFacade.performGetRequest( url + '/' + uuid + '/files/' + fileName + '/url', [] );
        if( pdfFileResponse.isSuccessful ) {
            window.location.href = pdfFileResponse.data
            $( '#view-attachment' ).attr( 'onClick', '() => this.fetchPdf( this )' ).removeClass( 'disabled' ); // re-enable button
        } else {
            this.toastMessages.toastError( 'Error while fetching PDF from backend service.', 'opsmsg-FETCHINGPDF' );
            $( '#view-attachment' ).attr( 'onClick', '() => this.fetchPdf( this )' ).removeClass( 'disabled' ); // re-enable button
        }

        this.setState( { loading: false } );
    };

    /**
     * Retrieves file as BASE64, converts to Blob then parses it in new tab
     **/
    fetchAsBinary( base64 ) {
        const blob = StreamConverter.base64ToBinary( base64, "application/pdf" );
        window.location.href = URL.createObjectURL( blob );
    }

    // Delete PDF on button click
    async deletePdf( self ) {

        this.setState( { loading: true } );
        $( '#delete-attachment' ).hide();
        $( '#view-attachment' ).hide();
        this.setState( { files: "" } );

        this.setState( { loading: false } );
    };

    disableMessage = async ( e ) => {
        let self = this;
        e.preventDefault(); // don't submit form

        confirmAlert({
            title: 'Please confirm',
            childrenElement: () => <div>The message can still be viewed in the archived message list, but it can not be retrieved.</div>,
            buttons: [
                {
                    label: 'Archive',
                    onClick: async () => {
                        let axiosData = {
                            "archiveUserName": localStorage.getItem('user')
                        };
                        let messagePostResponse = await this.apiFacade.performPostRequest( Config.getApiDomain() + '/ops-messages/v1/messages/' + this.state.UUID + '/archive', axiosData );
                        if( messagePostResponse.isSuccessful ) {
                            localStorage.removeItem( 'editorHtml' ); // remove editor value from localstorage
                            this.toastMessages.toastSuccess( 'Message has been archived.', 'opsmsg-ARCHIVED' );
                            setTimeout( function() {
                                window.location.replace( '/ops/messages' );
                            }, 1000);
                        } else {
                            this.toastMessages.toastError( 'Error while sending data to backend service.', 'opsmsg-POST' );
                        }

                    },
                },
                { label: 'Cancel' } // nothing happens
            ]
        })
    };

    toggleReadOnly() {
        this.setState(prevState => (
                {
                    isReadOnly: !prevState.isReadOnly
                }
            )
        );
    }

    buildLink = ( cell, row, self ) => {
        // Render/return button to open modal
        return (
            <Link to={ `/ops/message/${ row.UUID }/${ row.version }` + ( self.state.pageTitle === 'archived' ? '/archived' :'' ) } onClick={() => {window.location.href=`/ops/message/${ row.UUID }/0`}} className="btn btn-secondary btn-white-text"><i className="fas fa-eye"></i></Link>
        );
    }

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    };

    selectMessageInHistoryTable( uuid ) {
        // TODO ILO: once the url has been changed for the versioning, change this logic in order to select  correct version of the message. Messages will all have the same uuid, but a different version
        $("tbody > tr").each(function(index, element) {
            let row = $(this);
            let rowUuid = row.find("td:first").text();
            if( rowUuid == uuid) {
                row.addClass('selectedRow');
            }
        });
    }

    render() {
        let self = this;
        const aocs = [ 'All AOC', 'JAF', 'TUI', 'TFL', 'BLX', 'TOM' ];
        const arps = [ 'Departure', 'Arrival', 'Alternate' ];
        const tileClassNameStart = ({ date }) => {
            if( this.state.start !== undefined && date !== undefined ) {
                let startDate = moment( this.state.start ).format("YYYY-MM-DD");
                let calendarDate = moment( date ).format( "YYYY-MM-DD" );
                if( startDate === calendarDate && this.state.isReadOnly ) {
                    return 'selectedTile';
                }
            }
            return null;
        }
        const tileClassNameEnd = ({ date }) => {
            if( this.state.end !== undefined && date !== undefined ) {
                let endDate = moment( this.state.end ).format("YYYY-MM-DD");
                let calendarDate = moment( date ).format( "YYYY-MM-DD" );
                if( endDate === calendarDate && this.state.isReadOnly ) {
                    return 'selectedTile';
                }
            }
            return null;
        }
        const isReadOnly = this.state.isReadOnly && this.state.pageTitle === 'edit';
        
        return (
            <div className="container ops-messenger-container">
                <div className="row">
                    <div className="col-lg-12">
                        { self.state.loading &&
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        }
                        { self.state.pageTitle === 'create' &&
                            <h3 className="mt-5 mb-5">Create new ops message</h3>
                        }
                        { self.state.pageTitle === 'edit' &&
                            <div>
                                <h3 className="mt-5 mb-5">{ this.state.isReadOnly ? 'View' : 'Edit'} message <span>( {self.state.identifier} )</span>
                                    <button id="btnReadOnly" onClick={ this.toggleReadOnly }
                                            className="btn btn-lg btn-secondary text-uppercase font-weight-bold mb-2 mt-2 float-right"><i className="fas fa-pencil-square"></i>
                                        { isReadOnly ? ' Edit' : ' Read only'}
                                    </button>
                                </h3>
                            </div>
                        }
                        { self.state.pageTitle === 'archived' &&
                            <h3 className="mt-5 mb-5">View archived message <span>( {self.state.identifier} )</span></h3>
                        }

                        <form onSubmit={ async e => {
                            e.preventDefault();
                            await this.handleSubmit();
                        } }>
                            <div className="row">
                                {/* --- LEFT --- */}
                                <div className="col-lg-5 col-md-6 col-sm-12">

                                    <div className="horizontal-form-split"><span>1. Select criteria</span></div>

                                    <div className="form-group form-group-flex">
                                        { aocs.map( function( aoc, key ){
                                            return <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input aocBoxes" name="aocBoxes[]"
                                                       id={key} value={aoc}
                                                       onChange={ self.handleAOCChange }
                                                       disabled={ isReadOnly }
                                                />
                                                <label className="custom-control-label" htmlFor={key}>{aoc}</label>
                                            </div>;
                                        })}
                                    </div>

                                    <div className="form-group form-group-category" id="groupACReg">
                                        <input type="text" className="form-control group-readonly" id="inputACReg" value={this.state.REG} aria-describedby="registrationHelp" placeholder="Aircraft Registration"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="registrationHelp" className="form-text text-muted">Ex: DATUI or DATUI,DATUM,... or DA for all DA aircrafts</small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupACType">
                                        <input type="text" className="form-control" id="inputACType" value={this.state.TYP} aria-describedby="aircraftTypeHelp" placeholder="Aircraft Type"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="aircraftTypeHelp" className="form-text text-muted">Ex: B738 or B738,B737,.. or B78 for all 787-fleet. </small>
                                        <small id="aircraftTypeHelp" className="form-text text-muted">Possible types are E190, E295, B737, B738, B38M, B763, B788, B789 </small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupAirport">
                                        <input type="text" className="form-control" id="inputAirport" value={this.state.ARP} aria-describedby="airportHelp" placeholder="Airport ( ICAO )"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="airportHelp" className="form-text text-muted">Ex: EDDF or EBBR,EBOS,.... </small>
                                    </div>

                                    <div className="form-group form-group-flex">
                                        { arps.map( function( arp, key ){
                                            return <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input arpBoxes" name="arpBoxes[]"
                                                       id={'arp'+key} value={arp}
                                                       onChange={ self.handleARPBoolChange }
                                                       disabled={ isReadOnly }
                                                />
                                                <label className="custom-control-label" htmlFor={'arp'+key}>{arp}</label>
                                            </div>;
                                        })}
                                    </div>


                                    <div className="form-group form-group-category" id="groupCountry">
                                        <input type="text" className="form-control" id="inputCountry" value={this.state.CTY} aria-describedby="countryHelp" placeholder="Country"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="countryHelp" className="form-text text-muted">Ex: EB or EB,ED,... or K for all US </small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupFlightNumber">
                                        <input type="text" className="form-control" id="inputFlightNr" value={this.state.FNO} aria-describedby="flightNumberHelp" placeholder="Flight number"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="flightNumberHelp" className="form-text text-muted">Ex: X31234 or TOM4575,TOM4576,... </small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupAirspaces">
                                        <input type="text" className="form-control" id="inputAirspaces" value={this.state.AIRSPACES} aria-describedby="airspaceHelp" placeholder="Airspace"
                                               onChange={ this.handleCriteriaChange } readOnly={ isReadOnly } />
                                        <small id="airspaceHelp" className="form-text text-muted">Ex: EBBU or EBBU,LFFF,... or ED for all ED airspaces </small>
                                    </div>
                                </div>

                                {/* --- RIGHT --- */}
                                <div className="col-lg-7 col-md-6 col-sm-12">
                                    <div className="horizontal-form-split"><span>2. Select start and end date ( end date is optional )</span></div>

                                    <div className="form-group form-group-double-calendar">
                                        <Calendar
                                            onChange={ this.handleStartDateSelect }
                                            value={ this.state.start }
                                            tileDisabled = { ({ date }) => ( date.getDate() >= 1 && isReadOnly ) }
                                            tileClassName = { tileClassNameStart }
                                        />
                                        <Calendar
                                            onChange={ this.handleEndDateSelect }
                                            value={ this.state.end }
                                            tileDisabled = { ({ date }) => ( date.getDate() >= 1 && isReadOnly ) }
                                            tileClassName = { tileClassNameEnd }
                                        />
                                    </div>
                                    { this.state.end &&
                                    <button onClick={ e => { e.preventDefault(); this.handleEndDateSelect(); } }
                                            className="btn btn-md btn-warning btn-block mb-2 mt-2" disabled={ isReadOnly }>Clear end date ( set to UFN )</button>
                                    }

                                    {/* TODO ILO: EFB-2595: history of messages temporary disabled till the backend is ready. */}
                                    {/*{ ( this.state.pageTitle === 'edit' || self.state.pageTitle === 'archived' ) ?*/}
                                    {/*    <div className="horizontal-form-split"><span>3. Version history</span></div>*/}
                                    {/*: '' }*/}
                                    {/*{ this.state.messageHistory && ( this.state.pageTitle === 'edit' || self.state.pageTitle === 'archived' ) &&*/}
                                    {/*    <div className="table-wrapper">*/}
                                    {/*    <BootstrapTable*/}
                                    {/*        data={ this.state.messageHistory }*/}
                                    {/*        version='4'*/}
                                    {/*        selectedRow*/}
                                    {/*    >*/}
                                    {/*        <TableHeaderColumn isKey dataField='UUID' dataSort={ true } hidden={true} >UUID</TableHeaderColumn>*/}
                                    {/*        <TableHeaderColumn dataField='version' dataSort={ true } width={ '15%' } >Version</TableHeaderColumn>*/}
                                    {/*        <TableHeaderColumn dataField='createdOn' dataSort={ true } dataFormat={ this.isoDateFormat } width={ '30%' }>Created on</TableHeaderColumn>*/}
                                    {/*        <TableHeaderColumn dataAlign="right" dataField='user' dataSort={ true } width={ '40%' }>User</TableHeaderColumn>*/}
                                    {/*        <TableHeaderColumn dataField='view' dataFormat={ this.buildLink } formatExtraData={ this } width={ '10%' }></TableHeaderColumn>*/}
                                    {/*    </BootstrapTable>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                    {/*{ !this.state.messageHistory && ( this.state.pageTitle === 'edit' || self.state.pageTitle === 'archived' ) &&*/}
                                    {/*    <p>No history available</p>*/}
                                    {/*}*/}
                                </div>
                            </div>

                            {/* --- FULL WIDTH --- */}
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="horizontal-form-split mt-4"><span>4. Ops message content</span></div>

                                    <div className="form-group">
                                        <input type="text" className="form-control" id="inputMessageTitle" value={this.state.title} placeholder="Title for the message"
                                               onChange={ this.handleMessageChange } readOnly={ isReadOnly } />
                                    </div>

                                    <div className="form-group">
                                        <textarea name="inputMessageText" id="inputMessageText" value={this.state.text} cols="30" rows="10" className="form-control"
                                                  onChange={ this.handleMessageChange } placeholder="Include a message ( optional )" readOnly={isReadOnly} ></textarea>
                                    </div>

                                    {/* Check if uuid has an attachment, also show delete/download buttons */}
                                    <div className="horizontal-form-split mt-4"><span>5. Attachment</span></div>
                                    <div className="form-group form-group-flex">
                                        { this.state.pageTitle !== 'archived' && this.state.files.length === 0 &&
                                            <div>
                                            <input type="file" className="form-control max-input-width" id="inputUploadPdf" style={{height: "45px"}}
                                               accept="application/pdf" onChange={ this.handleFileUpload } disabled={ isReadOnly } />
                                                <small id="uploadHelp" className="form-text text-muted">Max. file size 4 MB</small>
                                            </div>
                                        }
                                        { this.state.files.length > 0 && this.state.pageTitle !== 'archived' &&
                                           <div>
                                               <a className="btn btn-md btn-warning mr-2" id="view-attachment" onClick={ async () => await this.fetchPdf( this )}>
                                                   <i className="fa fa-file-pdf-o"></i> View Attachment</a>
                                               { isReadOnly ? '' :
                                                   <a className="btn btn-md btn-warning mr-2" id="delete-attachment"onClick={ async () => await this.deletePdf( this ) }>
                                                       <i className="fa fa-trash-o" aria-hidden="true"></i> Delete Attachment</a>
                                               }
                                           </div>
                                        }
                                        { this.state.files.length === 1 && this.state.pageTitle === 'archived' &&
                                           <div>
                                                <a className="btn btn-md btn-warning mr-2" onClick={ async () => await this.fetchPdf( this )}>Download attachment</a>
                                           </div>

                                        }
                                    </div>


                                    <div className="button-group-flex">
                                        <button id="btnUpload"
                                                className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2 mt-2"
                                                type="submit"
                                                disabled={ isReadOnly }
                                        >
                                            {this.state.submitLabel}
                                        </button>
                                        { this.state.submitLabel === 'Save message' &&
                                        <button id="btnDisable" onClick={ this.disableMessage }
                                                className="btn btn-lg btn-danger btn-block text-uppercase mb-2 mt-2"
                                                disabled={ isReadOnly }
                                        >
                                            Disable message
                                        </button>
                                        }

                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
