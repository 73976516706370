import React from 'react';
import Config from '../../config';
import moment from 'moment'; // for formatting EOBT date
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Predispatch from '../../components/predispatch.component';
import {Link} from 'react-router-dom';
import ToastMessages from '@aviation/efb-react-sdk/main/helpers/toastMessages';
import {Loader} from '../../components/loading.component';

export default class Archive extends Predispatch {

    constructor(props) {
        super(props);
        this.state = {messages: [], loading: false, page: 1, LastEvaluatedKey: null, fetching: false};
        this.toastMessages = new ToastMessages();
    }

    async componentDidMount() {
        this.setState({loading: true});
        let hasAccess = await this.validateResourceAccess(this.RESOURCE_FLIGHT_ARCHIVE);
        if (hasAccess) {
            let api_endpoint = Config.getApiDomain() + '/ops-messages/v1/messages/archive';
            let {isSuccessful, data} = await this.apiFacade.performGetRequest(api_endpoint);
            if (isSuccessful) {
                await this.processArchivedMessagesResponse(data.body);
            } else {
                this.toastMessages.toastError('Error while fetching data from backend service.', 'opsmsg-GET');
            }

        }
        this.setState({loading: false});
    }

    async processArchivedMessagesResponse({archives, LastEvaluatedKey}) {
        let messages = [];
        let processLastEvaluatedKey = LastEvaluatedKey;
        for (let i in archives) {
            let message = this.cleanUpDisplayedMessages(archives[i]);
            messages.push(message);
        }
        while (processLastEvaluatedKey) {
            const apiEndpoint = `${Config.getApiDomain()}/ops-messages/v1/messages/archive?nextPage=${encodeURI(JSON.stringify(processLastEvaluatedKey))}`;
            const {isSuccessful, data} = await this.apiFacade.performGetRequest(apiEndpoint);
            if (isSuccessful) {
                const archivesToAdd = data.body.archives;
                processLastEvaluatedKey = data.body.LastEvaluatedKey;
                for (let i in archivesToAdd) {
                    let message = this.cleanUpDisplayedMessages(archivesToAdd[i]);
                    messages.push(message);
                }
            } else {
                processLastEvaluatedKey = null;
            }
        }
        this.setState({messages, LastEvaluatedKey});
    }

    // Clean up or format data before displaying in table
    cleanUpDisplayedMessages(message) {
        if (message.title.length > 48) {
            message.title = message.title.substring(0, 48) + '..';
        }

        return message;
    }

    dateFormat(cell) {
        if (cell && cell !== '') {
            const date = moment.utc(cell).format('YYYY-MM-DD');
            const time = moment.utc(cell).format('HH:mm:ss');
            return date + ' ' + time;
        } else {
            return '';
        }
    }

    linkCreator(cell, row) {
        return (
            <Link to={`/ops/message/${row.UUID}/archived`} className="nav-link">{row.title}</Link>
        );
    }

    render() {
        const {messages, loading, page, fetching} = this.state;

        const options = {
            page,
            sizePerPageList: [10, 25, 50],
            sizePerPage: 10
        };

        return (
            <div className="container container-search-flights">
                <div className="row">
                    <div className="col-lg-12 react-bs-table-container-wrapper">
                        <h3 className="page-title mt-5 mb-5">Archived messages list</h3>
                        {loading && <Loader display_text="Fetching Data"/>}
                        {fetching && <Loader display_text="Fetching Next Data Page please wait"/>}
                        {/* data must be array of objects */}
                        {!loading && !fetching &&
                            <BootstrapTable
                                // wrapperClasses="table-responsive"
                                // responsive='md'
                                options={options}
                                data={messages}
                                version="4"
                                pagination={true}
                                search={true}>
                                <TableHeaderColumn isKey dataField="UUID" dataSort={false}
                                                   hidden={true}>UUID</TableHeaderColumn>
                                <TableHeaderColumn dataField="title" width={'30%'} dataSort={false}
                                                   dataFormat={this.linkCreator}
                                                   formatExtraData={this}>Title</TableHeaderColumn>
                                <TableHeaderColumn dataField="AOC" width={'14%'}
                                                   dataSort={false}>AOC</TableHeaderColumn>
                                <TableHeaderColumn dataField="start" width={'9%'}
                                                   dataSort={false}>Start</TableHeaderColumn>
                                <TableHeaderColumn dataField="end" width={'9%'}
                                                   dataSort={false}>End</TableHeaderColumn>
                                <TableHeaderColumn dataField="creationTime" width={'14%'} dataSort={false}
                                                   dataFormat={this.dateFormat}>Created at</TableHeaderColumn>
                            </BootstrapTable>}
                    </div>
                </div>
            </div>
        );
    }
}
