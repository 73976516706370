import React from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Predispatch from "../../components/predispatch.component";

export default class Help extends Predispatch {

    async componentDidMount() {
        this.setState( { loading: true } );
        await this.validateResourceAccess( this.RESOURCE_OPS_MESSENGER );
        this.setState( { loading: false } );
    }

    render() {
        return (
            <div className="container help-container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Help</h3>

                        <div className="quick-links">
                            <a href="#introduction">Introduction</a>
                            <a href="#search">Search messages</a>
                            <a href="#edit">Edit a message</a>
                            <a href="#create">Create a new message</a>
                            <a href="#create-example">Examples for creating a message</a>
                            <a href="#export">Export messages in one PDF</a>
                        </div>

                        <h3>Support</h3>
                        <p>
                            Please get in touch with us if you have any questions or feedback: <a href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>
                        </p>

                        <h3 id="introduction">Introduction</h3>
                        <p>
                            Important information might be delivered to pilots on a short term basis.
                            These information are called 'Company Notams' and might be produced by flight ops or GOC.
                            The <em>OPS Messenger</em> portal manages these Company Notams.
                            Within this system Company Notams are called 'Messages'.
                            Every message consists of a title, a start date and optionally a text or attachment.
                        </p>

                        <div className="split-screen">
                            <img src={ require( '../images/criteria.png' ) } alt='' />
                            <p>
                                Pilots receive relevant messages during their briefing.
                                The author of a message can control to whom the message will be displayed.
                                This is done by defining criterias.
                                The more criterias are defined the more specific the message is.

                                <h4>Examples</h4>
                                <ul>
                                    <li>All pilots</li>
                                    <li>All pilots from operator TUI</li>
                                    <li>All flights with a Boeing 737-700 or 737-800</li>
                                    <li>All flights with airport EHAM</li>
                                </ul>
                            </p>

                        </div>

                        <h3 id="search">Search messages</h3>
                        <p>
                            The messages list shows all available messages.
                        </p>
                        <img src={ require( '../images/table.png' ) } alt='' />

                        <div className="split-screen">
                            <img src={ require( '../images/sort.png' ) } alt='' />
                            <p>
                                <b>Sort messages</b> <br/>
                                Click a column in the table header to sort messages.
                                <br/>
                                This example shows messages sorted by start date.
                            </p>
                        </div>

                        <div className="split-screen">
                            <img src={ require( '../images/search.png' ) } alt='' />
                            <p>
                                <b>Search messages</b> <br/>
                                Use the textfield above the table to filter the table.
                                <br/>
                                <em>Note: Only text in the table is searchable. The text content and attachments will not be found.</em>
                            </p>
                        </div>


                        <div className="split-screen">
                            <img src={ require( '../images/edit-uuid.png' ) } alt='' />
                            <p>
                                <b>Message identification</b> <br/>
                                Every message has a dedicated identifier.
                            </p>
                        </div>

                        <div className="split-screen">
                            <img src={ require( '../images/search-uuid.png' ) } alt='' />
                            <p>
                                <b>Find message by identification</b> <br/>
                                Use the identifier in the search field to find a specific message.
                            </p>
                        </div>


                        <h3 id="edit">Edit a message</h3>
                        <p>To edit a message, navigate to the message list and click on a title of the message you wish to update.</p>
                        <div className="split-screen">
                            <img src={ require( '../images/edit.png' ) } alt='' />
                            <p>
                                <b>Edit message content</b> <br/>
                                On the edit screen you see the same form as when creating a new message. All the values from the message are displayed in the correct input field. To change any value, just edit the content and click the 'save message' button.
                            </p>
                        </div>

                        <div className="split-screen">
                            <img src={ require( '../images/archive.png' ) } alt='' />
                            <p>
                                <b>Archive/disable a message</b> <br/>
                                To archive or disable a message, go to the edit page for a specific message and click the 'disable message' button. Confirm your action in the popup window, as this action can not be undone. <br/>
                                Note that archived or disabled messages will be moved ( every hour ) to the archive screen. There you can still view old messages, but you can not edit or re-enable them.
                            </p>
                        </div>

                        <h3 id="create">Creating a new message</h3>
                        <div className="split-screen">
                            <img src={ require( '../images/criteria.png' ) } alt='' />
                            <p>
                                <b>Step 1. Select your criteria</b> <br/>
                                Fill in each of the criteria you wish, all of the criteria in step 1 are optional.
                            </p>
                        </div>

                        <div className="split-screen">
                            <img src={ require( '../images/date.png' ) } alt='' />
                            <div>
                            <p>
                                <b>Step 2. Select start and end date</b> <br/>
                                Select a start date in the datepicker for when the message should be valid. The second datepicker allows you to set and expiration date for the message. This end date is optional and can be edited later on.
                            </p>
                            <p>
                                <h5>Example</h5>
                                Start: 2020-06-16<br/>
                                End: 2020-06-16<br/>
                                Message will be available from <em>2020-06-16 00:00Z</em> until <em>2020-06-16 23:59Z</em>.
                            </p>
                            </div>
                        </div>

                        <p>
                            <b>Step 3. Include the message</b> <br/>
                            Fill in the title for the message ( this is required ) and provide a more detailed explanation in the textbox if needed ( this is optional ).
                        </p>
                        <img src={ require( '../images/message.png' ) } alt='' />

                        <p>
                            <b>Step 4. Upload an attachment</b> <br/>
                            Additionally you can upload a PDF attachment to the message. This step is optional and can be removed or updated later on.
                        </p>
                        <img src={ require( '../images/attachment.png' ) } alt='' />


                        <h3 id="create-example">Examples for creating a message</h3>
                        <p>
                            <b>A message for all pilots</b> <br/>
                            To create a message for all pilots, uncheck all of the airline checkboxes.
                        </p>
                        <img src={ require( '../images/create-message-ex-1.png' ) } alt='' />

                        <p>
                            <b>A message with unspecified end date</b> <br/>
                            As the end date in the second datepicker is optional, you can leave this blank to create a message without an end date. <br/>
                            Note that the selected date(s) are marked in blue. The current date ( today ) is marked in yellow.
                        </p>
                        <img src={ require( '../images/create-message-ex-2.png' ) } alt='' />

                        <p>
                            <b>A message with two criteria</b> <br/>
                            Combining criteria is allowed. To create a message for all TUI Boeing 737, just check TUI and specify B737.
                        </p>
                        <img src={ require( '../images/create-message-ex-3.png' ) } alt='' />



                        <h3 id="export">Export messages in one PDF</h3>
                        <div className="split-screen">
                            <img src={ require( '../images/criteria-export.png' ) } alt='' />
                            <p>
                                <b>Step 1. Limit or filter messages by criteria</b> <br/>
                                Fill in each of the filters you wish, all filters in step 1 are optional. <br/>
                                To select messages for all airlines, leave the checkboxes empty/unselected.
                            </p>
                        </div>

                        <div className="split-screen">
                            <img src={ require( '../images/date-export.png' ) } alt='' />
                            <div>
                                <p>
                                    <b>Step 2. Select start and end date ( optional )</b> <br/>
                                    Select a start date in the datepicker to see all messages from the selected date until... The second datepicker allows you to set and end date for the messages. <br/>
                                    Both start and end date are optional. When selecting both, all messages between these dates will be shown. When selecting only and start or end date, all messages before/until will be shown.
                                </p>
                                <p>
                                    <h5>Example</h5>
                                    Start: 2021-10-20<br/>
                                    End: 2021-10-27<br/>
                                    Only message between be available from ( and including ) <em>2021-10-20</em> until ( and including )<em>2021-10-27</em>.
                                </p>
                            </div>
                        </div>

                        <p>Didn't find what you were looking for? You can email us at <a href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>, and we'll help you.</p>
                    </div>
                </div>
            </div>
        )
    }
}
