import React from 'react';
import Predispatch from "../../components/predispatch.component";
import 'react-calendar/dist/Calendar.css';
import GendecFlights from '../services/GendecFlights';
import Calendar from "react-calendar";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import DateFormatter from "../../helpers/DateFormatter";
import $ from "jquery";
import { MODULES } from '@aviation/efb-react-sdk/main/Authorisation/authoriser';
import {Link} from "react-router-dom";
import ToastMessages from "@aviation/efb-react-sdk/main/helpers/toastMessages";
import TokenHelper from "@aviation/efb-react-sdk/main/Authorisation/tokenHelper"


export default class Search extends Predispatch {

    constructor( props ) {
        super( props );
        this.toastMessages = new ToastMessages();
        this.tokenHelper = new TokenHelper();
        this.gendecFlights = new GendecFlights();
    }

    async componentDidMount() {
        this.setState( { loading: true } );
        let hasAccess = this.validateResourceAccess( this.RESOURCE_GENDEC )
        if( hasAccess ) {
            this.toggleForm();

            // You may call setState() immediately in componentDidMount(). It will trigger an extra rendering,
            // but it will happen before the browser updates the screen. This guarantees that even though the
            // render() will be called twice in this case, the user won’t see the intermediate state.

            // Store logged user in state
            this.setState( { 'creator': localStorage.getItem( "user" ) } );

            await this.fetchFlightFromTibco();
        }
        this.setState( { loading: false } );
    }

    // --- Call tibco by flightNr and flightDate to gather flightDetails
    fetchFlightFromTibco = async ( formParams = null ) => {
        this.setState( { loading: true } );

        let user = await this.tokenHelper.getCurrentUser();
        if( !user ){
            this.toastMessages.toastValidation( 'Invalid user detected.', 'gendec-GET' );
        } else {
            // console.log(user);
            //  user.roles = [];
            //  user.roles[0] = "GenDec-NL";
            //  user.roles[1] = "GenDec-SE";

            let airlinesToFilter = this.authoriser.getAirlinesByUserAndModule( user, MODULES[ 'GD' ] );

            // Filter flights on user-allowed airlines
            let flightParams = { 'airlinesToFilter': airlinesToFilter };

            // OPTIONAL | Add filters from the user form ( if it has been submitted )
            if( formParams ){
                if( formParams.flightDate ){
                    flightParams.flightDate = formParams.flightDate;
                }
            }

            // Filtering parameters from the form can be added in getFlightsByParams
            let gendecFlights = await this.gendecFlights.getGendecFlightsByParams( flightParams );
            if( !gendecFlights ) {
                this.setState({ 'flights': [] } );
                this.toastMessages.toastError( 'Unable to fetch flights for Gendec list.', 'gendec-GET' );
            } else {
                this.setState({ 'flights': gendecFlights } );
            }
        }

        this.setState( { loading: false } );
    };

    handleFlightDateSelect = ( date ) => {
        this.setState( { 'flightDate': date } );
    }

    handleSubmit = async () => {
        this.setState( { loading: true } );
        let strFlightDate = null;
        if( this.state.flightDate ) {
            strFlightDate = DateFormatter.getSqlDate( this.state.flightDate );
        }

        // If form is completely validated, start axios call
        let axiosData = {
            "flightDate": ( strFlightDate ),
        };

        await this.fetchFlightFromTibco( axiosData )

        $( '#gendecFlightsForm' ).slideToggle();
        $( '.fa-chevron-up' ).toggleClass( 'fa-chevron-down' ).toggleClass( 'fa-chevron-up' );

        this.setState( { loading: false } );
    };

    toggleForm = () => {
        $( '.fa-chevron-up' ).click( function() {
            $( '#gendecFlightsForm' ).slideToggle();
            $( this ).toggleClass( 'fa-chevron-down' ).toggleClass( 'fa-chevron-up' );
        });
    };

    buildLink = ( cell, row, self ) => {
        // Render/return button to open modal
        let flDate = row.DepartureTime;
        let readableDate = DateFormatter.getDateFromReadableDateTime( flDate );
        return (
            <Link to={"/gd/generate/" + row.AF_ID + "/" + readableDate} className="btn btn-primary btn-white-text">Create</Link>
        );
    };

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    };

    render() {
        let self = this;

        const options = {
            sizePerPageList: [ 10, 25, 50 ],
            sizePerPage : 50
        };

        return (
            <div className="container gendec-form-container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* --- Loading spinner --- */}
                        { self.state.loading &&
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                        }
                        <h3 className="title-with-toggle mt-5 mb-5">Filter flights <span class="fa fa-chevron-up"></span></h3>
                        <form id="gendecFlightsForm" onSubmit={ async e => {
                            e.preventDefault();
                            await this.handleSubmit();
                        } }>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">

                                    <div className="horizontal-form-split mt-4"><span>Filter flights by date</span></div>

                                    <div className="form-group form-group-calendar">
                                        <Calendar
                                            onChange={ this.handleFlightDateSelect }
                                            value={ this.state.flightDate }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* --- FULL WIDTH --- */}
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="button-group-flex">
                                        <button id="btnUpload" type="submit"
                                                className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2 mt-2">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Gendec</h3>

                        { this.state.flights && this.state.flights.length !== 0 &&
                        <BootstrapTable
                            // wrapperClasses="table-responsive"
                            // responsive='md'
                            options={ options }
                            data={ this.state.flights }
                            version='4'
                            pagination={ true }
                            search={ true }>
                            <TableHeaderColumn isKey dataField='AF_ID' dataSort={ true } hidden={true} >AfId</TableHeaderColumn>
                            <TableHeaderColumn dataField='AircraftRegistration' hidden={true} >AC reg</TableHeaderColumn>
                            <TableHeaderColumn dataField='AircraftOwner' hidden={true} >Aircraft Owner</TableHeaderColumn>
                            <TableHeaderColumn dataField='FlightNumber' dataSort={ true }>Flight</TableHeaderColumn>
                            <TableHeaderColumn dataField='DepartureTime' dataSort={ true } dataFormat={ this.isoDateFormat }>STD</TableHeaderColumn>
                            <TableHeaderColumn dataField='DepartureAirport' dataSort={ true }>Departure</TableHeaderColumn>
                            <TableHeaderColumn dataField='ArrivalAirport' dataSort={ true }>Arrival</TableHeaderColumn>
                            <TableHeaderColumn dataField='AF_ID' dataSort={ true } dataFormat={ this.buildLink } formatExtraData={ this }>File</TableHeaderColumn>
                        </BootstrapTable>
                        }

                        { !this.state.flights &&
                            <p>Fetching flights...</p>
                        }

                        { this.state.flights && this.state.flights.length === 0 &&
                            <p>Could not fetch flights from tibco API.</p>
                        }

                    </div>
                </div>
            </div>
        )
    }
}