

const DateFormatter = {

	/**
	 * Gets readable date to display in views
	 * ( ex input: 2020-09-18T10:06:23.000Z )
	 * ( ex output: 2020-09-18 10:06:23 )
	 *
	 * @param string dateTime ( iso-format )
	 * @return string readableDateTime
	 */
	getReadableDateTimeFromIso: function( dateTime ) {
		dateTime = dateTime.replace( 'T', ' ' );
		dateTime = dateTime.replace( 'Z', '' );
		let split = dateTime.split( '.' ); // remove milliseconds

		if( split[0] !== ''){
			return split[0] + 'Z';
		}else{
			return split[0];
		}
	},

	getDateFromReadableDateTime: function( readableDateTime ) {
		let splittedDateTime = readableDateTime.split('T');
		return splittedDateTime[0];
	},

	/**
	 * Gets readable date to display in views
	 * ( ex input: 18/Sep/2020:10:06:23 +0000 )
	 * ( ex output: 2020-09-18 10:06:23 )
	 *
	 * @param string date
	 * @return string readableDate
	 */
	getReadableDateFromFlightkeyDownloadTime: function( date ) {
		// For now, remove +0000
		date = date.replace( ' +0000', '' );

		// Extract day and month from string
		let splitString = date.split( '/', 2 );
		let day = splitString[ 0 ];

		let month;
		let monthArrayValue = this.getMonthValueFromString( splitString[ 1 ].toUpperCase() );
		let monthPlusOne = parseInt( monthArrayValue ) + 1;
		let monthString = monthPlusOne.toString();
		if( monthString.length === 1 ){
			// If month is single digits, add 0 in front
			month = 0 + monthString;
		}  else {
			month = monthString;
		}

		// Extract year from string
		let extractedYear = date.substr( date.length - 13 ); // - 8 for "2020:10:06:23"
		let year = extractedYear.substring( 0, extractedYear.length - 9 ); // remove last 9 character from string ":10:06:23"

		// Extract time from string
		let time = date.substr( date.length - 8 ); // - 8 for ":10:06:23"

		return year + '-' + month + '-' + day + ' ' + time + 'Z';
	},

	/**
	 * Gets well formatted SQL date from js date
	 *
	 * @param Date date
	 *
	 * @return string sqlDate
	 */
	getSqlDate: function( date ) {
		let month = date.getMonth() + 1;
		let strMonth = month < 10 ? "0" + month : month;
		let strDay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
		return date.getFullYear() + "-" + strMonth + "-" + strDay;
	},

	/**
	 * Creates date from SQL formatted date string
	 *
	 * @param string sqlDate
	 *
	 * @returns {Date}
	 */
	createDateFromSqlDate: function( sqlDate ) {
		let arrDate = sqlDate.split('-');
		let date = new Date();
		date.setFullYear( arrDate[0] );
		date.setMonth( parseInt( arrDate[1] ) - 1 );
		date.setDate( parseInt( arrDate[2] ) );
		return date;
	},

	/**
	 * Gets month value from string
	 *
	 * @param string monthStr
	 *
	 * @returns {*}
	 */
	getMonthValueFromString: function( monthStr ) {
		let monthStrings = this.getMonthNames();
		let monthsStringsShort = this.getMonthNamesShort();
		for( let i in monthStrings ) {
			if( monthStrings[i] === monthStr ) {
				return i;
			}
		}
		for( let i in monthsStringsShort ) {
			if( monthsStringsShort[i] === monthStr ) {
				return i;
			}
		}
		return -1;
	},
	getMonthNames: function() {
		return [
			"January", "February", "March",
			"April", "May", "June", "July",
			"August", "September", "October",
			"November", "December"
		];
	},
	getMonthNamesShort: function() {
		return [
			'JAN', 'FEB', 'MAR', 'APR', 'MAY',
			'JUN', 'JUL', 'AUG', 'SEP', 'OCT',
			'NOV', 'DEC'
		];
	}
}

export default DateFormatter;

