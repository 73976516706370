import React from 'react';

export const Loader = ({display_text}) => {
    return (
        <div>
            <div className="loader_container">
                <div className="loader_text">{display_text}</div>
                <div className="lds-dual-ring"></div>
            </div>
        </div>
    );
};