import Config from "../../config";
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';

export default class RetriggerFlightId {

    constructor(){
        this.apiFacade = new ApiFacade();
    }

    async retriggerFlight( flightId ) {
        let url = Config.getApiDomain()  + "/returndata/v1/flights/" + flightId + "/returnjson/retrigger";
        let retriggerResponse = await this.apiFacade.performGetRequest( url );
        if( retriggerResponse.isSuccessful ) {
            return true;
        } else {
            return false;
        }
    }
}