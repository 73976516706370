import React from 'react';
import Config from '../config';
import Predispatch from "./predispatch.component";

export default class Login extends Predispatch {

    buildAuthUrl() {
        return Config.getAuthDomain()+"/login?client_id="+Config.getAdLoginClientId()+"&response_type=token&redirect_uri="+Config.getPortalDomain();
    }

    adLogin( self, e ) {
        e.preventDefault();
        window.location.href = self.buildAuthUrl();
    }

    componentDidMount() {
        //
    }

    render() {
        return (
            <div className="container-fluid container-login-logout">
                <div className="row no-gutter">
                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                    <div className="col-md-8 col-lg-6">
                        <div className="login d-flex align-items-center py-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9 col-lg-8 mx-auto">
                                        <div className="message-welcome">
                                            <p className="title mb-3">Back Office Portal</p>
                                            <form method="POST" onSubmit={(e) => this.login(this, e)}>
                                                {/* Disabled in PROD until AD role is working */}
                                                { /*Config.getEnvironment() !== 'prod' &&*/
                                                    <button className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2"
                                                            type="submit" onClick={(e) => this.adLogin(this, e)}>Login with TUI AD</button>
                                                }
                                                <div className="ad-login-message">
                                                    <p>
                                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                        Please use tuiad\name.surname as your user account
                                                    </p>
                                                </div>

                                                {/*<a className="btn btn-block text-uppercase font-weight-bold mb-2" href={this.authDevUrl} style={{width: "100%"}}>*/}
                                                {/*    Authenticate via TUI AD*/}
                                                {/*</a>*/}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
