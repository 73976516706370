import React from 'react';
import Predispatch from "../../components/predispatch.component";
import DateFormatter from "../../helpers/DateFormatter";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Config from "../../config";

class FileList extends Predispatch {
    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
    }

    buildDownloadLink = ( cell, row, self ) => {
        // Render/return button to open modal
        return (
            <a onClick={(e) => self.downloadFile( row.objectName, row.filetype, row.dataType )} className="btn btn-primary">Download</a>
        );
    }

    /**
     * Downloads and renders file in-browser
     *
     * @param {string} filename
     * @param {string} filetype
     * @param {string} datatype
     */
    async downloadFile( filename, filetype, datatype ){
        let self = this;

        // Remove subdirectory ( filename ) from filename for url
        let filenameSplit = filename.split( '/' );
        let filenameWithoutSubdirectory = filenameSplit[ 1 ];

        // Fetch part of url by dataType
        let apiCallType = '';
        switch( datatype ) {
            case 'Submitted':
                apiCallType = 'returnjson'
                break;
            case 'Signed':
                apiCallType = 'fuelsignature'
                break;
            case 'FilledCbp':
                apiCallType = 'filledcbp'
                break;
            default:
                apiCallType = 'flightdocs'
        }

        // Build axios params
        let url = '/returndata/v1/flights/' + this.props.afId + '/' + apiCallType;
        if( apiCallType === 'flightdocs' ) {
            url += '/';
        } else {
            url += '/files/';
        }
        url += filenameWithoutSubdirectory;

        // If filetype = pdf, add 'responseType blob' in headers
        if( filetype === 'pdf'|| filetype === 'png' || filetype === 'jpeg' ){
            let fileResponse;
            if( filetype === 'pdf' ) {
                fileResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + url, [], 'application/' + filetype, "blob" );
            } else {
                fileResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + url, [], 'image/' + filetype, "blob" );
            }
            if( fileResponse.isSuccessful ) {
                let file = '';
                if( filetype === 'pdf' ) {
                    file = new Blob( [ fileResponse.data ], { type: 'application/' + filetype } );
                } else {
                    file = new Blob( [ fileResponse.data ], { type: 'image/' + filetype } );
                }
                let a = document.createElement( 'a' );
                a.target = '_blank';
                a.href = URL.createObjectURL( file );
                a.click();
            } else {
                this.toastMessages.toastError( 'Could not retrieve file.', 'returndata-GET' );
            }

            self.setState( { loading: false } );
        } else {
            let fileResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + url );
            if( fileResponse.isSuccessful ) {
                let file = '';
                switch( datatype ) {
                    case 'Submitted':
                    case 'Signed':
                        file = new Blob( [ JSON.stringify( fileResponse.data ) ], { type: fileResponse.headers[ 'content-type' ] } );
                        break;
                    default:
                        // code block
                        break;
                }

                let a = document.createElement( 'a' );
                a.target = '_blank';
                a.href = URL.createObjectURL( file );
                a.click();
            } else {
                this.toastMessages.toastError( 'Could not retrieve file.', 'returndata-GET' );
            }

            self.setState( { loading: false } );
        }
    }

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    };

    render() {
        return(
            <BootstrapTable
                // wrapperClasses="table-responsive"
                // responsive='md'
                // options={ options }
                data={ this.props.files }
                version='4'
                pagination={ false }
                search={ true }>
                {/*<TableHeaderColumn isKey dataField='flightPlanId' dataSort={ true } hidden={ true }>Flightplan Id</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='dataType' dataSort={ true } width={ '12%' }>Source</TableHeaderColumn>
                <TableHeaderColumn isKey dataField='objectName' dataSort={ true } width={ '55%' } >Filename</TableHeaderColumn>
                <TableHeaderColumn dataField='filetype' dataSort={ true } width={ '7%' }>Ext.</TableHeaderColumn>
                <TableHeaderColumn dataField='insertDateTime' dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this } width={ '15%' }>Modified</TableHeaderColumn>
                <TableHeaderColumn dataField='uuid' dataFormat={ this.buildDownloadLink } formatExtraData={ this }  width={ '11%' }>Download</TableHeaderColumn>
            </BootstrapTable>

        )
    }
}

export default FileList;