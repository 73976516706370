import React from 'react';
import $ from "jquery";

import Predispatch from "../../components/predispatch.component";
import Config from "../../config";
import DateFormatter from "../../helpers/DateFormatter";
import GendecFlights from '../services/GendecFlights';
import Calendar from 'react-calendar'; // calendar
import 'react-calendar/dist/Calendar.css';

const RolesOrder = [ 'CP', 'FO', 'CH', '3M', 'SO', 'RP', 'SFI', 'XTR', 'JFO', 'SFO', 'AP', 'PU', 'CD', 'SCA', 'CM', 'SCC', 'ASC', 'CCM', 'CC', 'SNY', 'SA', 'CA', 'CCR', 'CCC', 'T2S', 'GS', 'DH' ];

export default class Generate extends Predispatch {
    constructor( props ) {
        super( props );
        this.changeDate = this.changeDate.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.gendecFlights = new GendecFlights();
    }

    async componentDidMount() {
        this.setState( { loading: true } );
        let hasAccess = await this.validateResourceAccess( this.RESOURCE_GENDEC );
        if( hasAccess ) {
            // You may call setState() immediately in componentDidMount(). It will trigger an extra rendering,
            // but it will happen before the browser updates the screen. This guarantees that even though the
            // render() will be called twice in this case, the user won’t see the intermediate state.

            this.setState({
                // Store logged user in state
                'creator': localStorage.getItem( "user" ),
                // Counter for amount of crew member form fields
                'amountOfCrewMembers': 1 // default 1
            } );

            // Retrieve afId and std from url/props, get flight details and prepopulate form
            let flightId = this.props.match.params[ 'flightid' ];
            let std = this.props.match.params[ 'std' ];
            if( flightId !== undefined && std !== undefined ) {
                let flightDetails = await this.gendecFlights.callTibcoFlightDetails( flightId, std );
                let persons = await this.gendecFlights.getGendecPersons( flightId );
                if( ! flightDetails ) {
                    this.toastMessages.toastError( 'Error while getting flight details', 'gendec-GET' );
                } else if( ! persons ){
                    this.toastMessages.toastError( 'Error while getting the gendec persons', 'gendec-GET' );
                } else {
                    this.prePopulateFormWithTibcoFlightDetails( flightDetails, persons );
                }

            }
        }

        this.setState( { loading: false } );
    }

    // --- Populates the input form fields with tibco data
    prePopulateFormWithTibcoFlightDetails( allFlightInformation, persons ){
        let flightDetails = allFlightInformation[ 0 ];
        let flightInformation = allFlightInformation[ 1 ];

        let flightNr = flightInformation.FlightInformation.FlightPrefix + flightInformation.FlightInformation.FlightNumber;
        if( flightInformation.FlightInformation.FlightSuffix ){
            flightNr += flightInformation.FlightInformation.FlightSuffix;
        }

        // Set values in state ( for GenDec api call )
        // HTML form fields, will be automatically populated with values in state
        this.setState({
            'operator': flightDetails.FlightOwner,
            'registration': flightDetails.AircraftRegistration,
            'flightnr': flightNr,
            'departure': flightDetails.DepartureAirport + '/' + flightInformation.FlightInformation.FlightDeparture,
            'arrival': flightDetails.ArrivalAirport + '/' + flightInformation.FlightInformation.FlightDestination
        });

        this.changeDate( DateFormatter.createDateFromSqlDate( flightDetails.Date ) );

        // Loop all crewMembers and add correct amount of input fields to populate
        for( let i = 0; i < persons.length; i++ ){
            let inputCounter = i + 1;
            $( '#inputName-' + inputCounter ).val( persons[ i ].Name );
            $( '#inputSurname-' + inputCounter ).val( persons[ i ].Surname );
            $( '#inputRole-' + inputCounter ).val( persons[ i ].Role );
            // On last iteration of loop, do not add anymore input fields
            if( i < persons.length - 1 ){
                this.addCrewMember();
            }
        }
    }

    // --- Triggers on change of input form fields
    changeInput = ( e ) => {
        // Set input values to state
        switch ( e.target.id ){
            case "inputOperator":
                this.setState( { "operator" : e.target.value.trim() } );
                break;
            case "inputRegistration":
                this.setState( { "registration" : e.target.value.trim() } );
                break;
            case "inputFlightNr":
                this.setState( { "flightnr" : e.target.value.trim() } );
                break;
            case "inputDeparture":
                this.setState( { "departure" : e.target.value.trim() } );
                break;
            case "inputArrival":
                this.setState( { "arrival" : e.target.value.trim() } );
                break;
            default:
                break;
        }
    }

    // --- Add date to state when date is selected from datepicker
    changeDate( date ) {
        // Format and store in state
        let sqlDate = DateFormatter.getSqlDate( date );
        this.setState({
            'flightDate': sqlDate, // for use in axios post data
            'flightDateObject': date // for use in react-calendar
        });
    }

    // --- Triggers when user adds another crew member input field
    addCrewMember = () => {
        // Increase counter of crew members
        let newAmountOfCrewMembers = this.state.amountOfCrewMembers + 1;
        // Temporary workarround for loading the page twice. This prevents showing to much inputfields
        if( !Number.isNaN(newAmountOfCrewMembers) ) {
            this.setState( { 'amountOfCrewMembers': newAmountOfCrewMembers } );

            // Add HTML form fields to view
            let crewMemberInputField = '<div class="form-group-flex">' +
                '<div class="form-group form-group-category" id="name-' + newAmountOfCrewMembers + '">' +
                '<input type="text" class="form-control" id="inputName-' + newAmountOfCrewMembers + '" placeholder="Name" />' +
                '</div>' +
                '<div class="form-group form-group-category" id="surname-' + newAmountOfCrewMembers + '">' +
                '<input type="text" class="form-control" id="inputSurname-' + newAmountOfCrewMembers + '" placeholder="Surname" />' +
                '</div>' +
                '<div class="form-group form-group-flex" id="role-' + newAmountOfCrewMembers + '">' +
                '<select id="inputRole-' + newAmountOfCrewMembers + '">'+this.fillCrewRoleOptions(true)+'</select>' +
                '</div>' +
                '</div>';

            $( "#crewMemberList" ).append( crewMemberInputField );
        }
    }

    // --- Run after user submits form
    handleSubmit = async () => {
        this.setState( { loading: true } );

        let crew = this.gatherAllCrewMembers();

        // If form is completely validated, start axios call
        let axiosData = {
            "flight": {
                "operator": ( this.state.operator === undefined ? '' : this.state.operator ),
                "registration": ( this.state.registration === undefined ? '' : this.state.registration ),
                "flightNumber": ( this.state.flightnr === undefined ? '' : this.state.flightnr ),
                "date": ( this.state.flightDate === undefined ? '' : this.state.flightDate ),
                "departure": ( this.state.departure === undefined ? '' : this.state.departure ),
                "arrival": ( this.state.arrival === undefined ? '' : this.state.arrival ),
            },
            "crew": crew
        };

        // Sending the formdata to the backend
        let gendecPostResponse = await this.apiFacade.performPostRequest( Config.getApiDomain() + '/gendec/v1/gendecs', axiosData, [] ,'application/pdf', null,'arraybuffer' );
        if( gendecPostResponse.isSuccessful ) {
            let file = new Blob([ gendecPostResponse.data ], { type: 'application/pdf' } );
            let a= document.createElement( 'a' );
            a.target= '_blank';
            a.href= URL.createObjectURL( file );
            a.click();
        } else {
            this.toastMessages.toastError( 'Error while sending data to backend service.', 'gendec-POST' );
        }

        this.setState( { loading: false } );
    }

    // --- Crew member input, because amount of members is variable
    gatherAllCrewMembers() {
        let crew = [];

        // Get input from form fields, loop as many times as there are crew members
        for( let i = 1; i < this.state.amountOfCrewMembers + 1; i++ ){
            let name = $( '#inputName-' + i ).val();
            let surname = $( '#inputSurname-' + i ).val();
            let role = $( '#inputRole-' + i ).val();

            if( name.trim() === '' && surname.trim() === '' ){
                continue; // skip if both input fields are empty
            }
            let member = {
                'name': name.trim(),
                'surname': surname.trim(),
                'role': role.trim()
            };
            crew.push( member );
        }

        return crew;
    }

    fillCrewRoleOptions( asString ){

        if( asString ){
            return RolesOrder.map(role => '<option value="'+role+'">'+role+'</option>');
        }else{
            return RolesOrder.map(role => <option value={role}>{role}</option>);
        }

    }


    render() {

        let self = this;

        return (
            <div className="container form-container gendec-generate-container">
                <form onSubmit={ async e => {
                    e.preventDefault();
                    await this.handleSubmit();
                } }>
                    <div className="row">
                        {/* --- Loading spinner --- */}
                        { self.state.loading &&
                        <div className="spinner">
                            <div className="double-bounce1"> </div>
                            <div className="double-bounce2"> </div>
                        </div>
                        }

                        {/* --- LEFT --- */}
                        <div className="col-lg-4 col-md-6 col-sm-12">

                            <div className="horizontal-form-split"><span>Flight information</span></div>

                            <div className="form-group form-group-category" id="operator">
                                <input type="text" className="form-control" id="inputOperator" aria-describedby="operatorHelp" placeholder="Operator / Flight Owner"
                                       value={ this.state.operator } onChange={ this.changeInput }/>
                                <small id="operatorHelp" className="form-text text-muted">Ex: TUI</small>
                            </div>

                            <div className="form-group form-group-category" id="registration">
                                <input type="text" className="form-control" id="inputRegistration" aria-describedby="registrationHelp" placeholder="Aircraft registration"
                                       value={ this.state.registration } onChange={ this.changeInput }/>
                                <small id="registrationHelp" className="form-text text-muted">Ex: D-ATUI</small>
                            </div>

                            <div className="form-group form-group-category" id="flightNr">
                                <input type="text" className="form-control" id="inputFlightNr" aria-describedby="flightNrHelp" placeholder="Flight number"
                                       value={ this.state.flightnr } onChange={ this.changeInput }/>
                                <small id="flightNrHelp" className="form-text text-muted">Ex: X31234</small>
                            </div>

                            <div className="form-group form-group-category" id="departure">
                                <input type="text" className="form-control" id="inputDeparture"
                                       aria-describedby="departureHelp" placeholder="Departure (ICAO)"
                                       value={ this.state.departure } onChange={ this.changeInput }/>
                                <small id="departureHelp" className="form-text text-muted">Ex: EBBR/BRU for Brussels.
                                    (ICAO/IATA)</small>
                            </div>

                            <div className="form-group form-group-category" id="arrival">
                                <input type="text" className="form-control" id="inputArrival"
                                       aria-describedby="arrivalHelp" placeholder="Arrival (ICAO)"
                                       value={ this.state.arrival } onChange={ this.changeInput }/>
                                <small id="arrivalHelp" className="form-text text-muted">Ex: EDDF/FRA for Frankfurt.
                                    (ICAO/IATA)</small>
                            </div>

                            <div className="horizontal-form-split"><span>Select flight date</span></div>

                            <div className="form-group form-group-calendar" id="date">
                                <Calendar
                                    onChange={ this.changeDate }
                                    value={ this.state.flightDateObject }
                                />
                            </div>

                        </div>

                        {/* --- RIGHT --- */}
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="horizontal-form-split"><span>Member list</span></div>

                            <div id="crewMemberList">
                                <div className="form-group-flex ">
                                    <div className="form-group form-group-category form-group-category-closer" id="name-1">
                                        <input value={ this.state.crew } type="text" className="form-control" id="inputName-1" placeholder="Name"/>
                                    </div>

                                    <div className="form-group form-group-category form-group-category-closer" id="surname-1">
                                        <input value={ this.state.crew } type="text" className="form-control" id="inputSurname-1" placeholder="Surname"/>
                                    </div>

                                    <div className="form-group form-group-flex" id="role-1">
                                        <select id="inputRole-1">
                                            {   this.fillCrewRoleOptions(false) }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold" id="addCrewMember"
                                    onClick={ e => { e.preventDefault(); this.addCrewMember(); } }>
                                Add crew member
                            </button>

                        </div>
                    </div>

                    {/* --- FULL WIDTH --- */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="button-group-flex">
                                <button id="btnUpload"
                                        className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2 mt-2"
                                        type="submit">
                                    Generate gendec
                                </button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        )
    }
}