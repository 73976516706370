import Config from '../../config';
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';
import DateFormatter from "../../helpers/DateFormatter";

export default class Flights {

    constructor() {
        this.apiFacade = new ApiFacade();
    }

    /**
     * Gets flights params
     *
     * @param {object} params
     * @return {array}
     */
    async getFlightsByParams( params = {} ){
        let flightsStr = Config.getApiDomain() + '/flightkeys/v1/flights?scheduledDateOfDeparture=';

        let flightsStrEnd = '';
        if( params.hasOwnProperty('flightNr') && params.flightNr !== null ) {
            flightsStrEnd += '&idpsFlightNumber=' + params.flightNr;
        }

        if( params.hasOwnProperty('departure') && params.departure !== null ) {
            flightsStrEnd += '&departureAirport=' + params.departure;
        }

        if( params.hasOwnProperty('destination') && params.destination !== null ) {
            flightsStrEnd += '&arrivalAirport=' + params.destination;
        }

        let filterParams = {};
        if( params.hasOwnProperty('airlinesToFilter') && params.airlinesToFilter !== null ) {
            filterParams['airlinesToFilter'] = (params.airlinesToFilter);
        }

        if( ( params.hasOwnProperty('startDate') && params.startDate !== null && params.startDate !== '' ) &&
            ( params.hasOwnProperty('endDate') && params.endDate !== null && params.endDate !== '' )
        ) {
            // In case startDate AND endDate are selected
            let startDate = new Date( params.startDate );
            let endDate = new Date( params.endDate );

            let incrementDate = startDate;
            let allFlights = [];
            let flightPromises = [];

            while ( incrementDate <= endDate ) {
                let formattedIncrementDate = DateFormatter.getSqlDate( incrementDate );
                let tempFlightStr = flightsStr + formattedIncrementDate + flightsStrEnd;
                flightPromises.push( this.getFlights( tempFlightStr ) );
                incrementDate.setDate( incrementDate.getDate() + 1 );
            }

            let flightArrs = await Promise.all( flightPromises );
            if( flightArrs ){
                for( let i in flightArrs ) {
                    let flightsPerDay = flightArrs[ i ];
                    allFlights = allFlights.concat( flightsPerDay );
                }

                return this.filterFlightsByParams( filterParams, allFlights );
            }
        } else if( params.hasOwnProperty( 'startDate' ) && params.startDate !== null && params.startDate !== '' ) {
            // In case only startDate is selected
            flightsStr += params.startDate;
            flightsStr += flightsStrEnd;

            let flights = await this.getFlights( flightsStr );
            if( flights ){
                return this.filterFlightsByParams( filterParams, flights );
            }
        } else {
            // In case no date is selected
            let today = new Date();
            let formattedDate = DateFormatter.getSqlDate( today );
            flightsStr += formattedDate;
            flightsStr += flightsStrEnd;

            let flights = await this.getFlights( flightsStr );
            if( flights ){
                return this.filterFlightsByParams( filterParams, flights );
            }
        }

        return []; // expected array
    }

    /**
     * Get flights
     *
     * @param {String} flightsStr
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getFlights( flightsStr ) {
        let flightsResponse = await this.apiFacade.performGetRequest( flightsStr );
        if( flightsResponse.isSuccessful ) {
            return flightsResponse.data.flight;
        } else {
            return null;
        }

    }

    /**
     * Gets Tibco Flight information by flight id
     *
     * @param {number} flightId
     *
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    async getTibcoFlightByFlightId( flightId ) {
        let flId = flightId;
        let tibcoUrlFlightInformation = '/tibco/v1/getFlightInformation/' + flId;
        let flightResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + tibcoUrlFlightInformation );
        if( flightResponse.isSuccessful ) {
            return flightResponse.data;
        } else {
            return false;
        }

    }

    /**
     * Filters flights by airline
     *
     * @param params {object}
     * @param {array} flights
     * @returns {array} flights
     */
    filterFlightsByParams( params = {}, flights ){
        let filteredFlights = [];

        for( let i in flights ){
            let flight = flights[i];
            if( params.hasOwnProperty( 'airlinesToFilter' ) && params.airlinesToFilter !== null ){
                for( let i in params.airlinesToFilter ){
                    let filterAirline = params.airlinesToFilter[i];
                    // Check if airline is same as desired airlines and if the flight isn't already added to the list
                    if( flight.airline === filterAirline && !Object.keys( filteredFlights ).includes( flight.af_id ) ){
                        filteredFlights[ flight.af_id ] = flight;
                    }
                }
            }
        }

        return Object.values( filteredFlights );
    }

}
