import React from 'react';
import {Link} from "react-router-dom";
import Predispatch from "../../components/predispatch.component";

export default class Home extends Predispatch {

    async componentDidMount() {
        this.setState( { loading: true } );
        // You may call setState() immediately in componentDidMount(). It will trigger an extra rendering,
        // but it will happen before the browser updates the screen. This guarantees that even though the
        // render() will be called twice in this case, the user won’t see the intermediate state.
        await this.validateResourceAccess( this.RESOURCE_OPS_MESSENGER );
        this.setState( { loading: false } );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Ops Messenger</h3>
                    </div>
                    <div className="col-lg-6">
                        <h4>Welcome!</h4>
                        <div className="row">
                            <Link to="/ops/message" className="nav-link">Create new message</Link>
                            <Link to="/ops/messages" className="nav-link">Search messages</Link>
                            <Link to="/ops/archive" className="nav-link">Archive</Link>
                        </div>
                        <p className="mt-5">If you run into any problems, please have a look <Link
                            to="/ops/help">here</Link>.</p>
                        <p>Please send feedback to <a
                            href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>.</p>
                    </div>
                    <div className="col-lg-6">
                        <h4>Changelog</h4>
                        <div>
                            <h5>2021-10-20</h5>
                            <ul>
                                <li><i className="fa fa-filter" aria-hidden="true"></i> better filtering per column</li>
                                <li><i className="fa fa-star" aria-hidden="true"></i> new page to export multiple messages in one PDF</li>
                            </ul>
                            <h5>2021-02-01</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> new airspace type criteria</li>
                            </ul>
                            <h5>2020-09-17</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Authorization with Active Directory</li>
                                <li><i className="fa fa-bug" aria-hidden="true"></i> input validation on Country & Airport
                                </li>
                            </ul>
                            <h5>2020-07-08</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> New airport type criteria</li>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Import FlightKeys company messages</li>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Show simple message identifiers</li>
                                <li><i className="fa fa-bug" aria-hidden="true"></i> Prevent double submit of buttons</li>
                            </ul>

                            <h5>2020-06-19</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Search for text content</li>
                                <li><i className="fa fa-bug" aria-hidden="true"></i> Flight number criteria can have two digits</li>
                                <li><i className="fa fa-book" aria-hidden="true"></i> Explain logic of start and end dates</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}