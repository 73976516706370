import Config from '../../config';
import ApiFacade from '@aviation/efb-react-sdk/main/api/apiFacade';

export default class MessageManager {

    constructor(){
        this.apiFacade = new ApiFacade();
    }

    async getMessageByUUID( id ) {
        let url = Config.getApiDomain() + "/ops-messages/v1/messages/"+id;
        let messageResponse = await this.apiFacade.performGetRequest( url );
        if( messageResponse.isSuccessful ) {
            return messageResponse.data.body;
        } else {
            return null;
        }

    }

    async getArchivedMessageByUUID( id ) {
        let url = Config.getApiDomain() + "/ops-messages/v1/messages/archive/"+id;
        let archivedMessageResponse = await this.apiFacade.performGetRequest( url );
        if( archivedMessageResponse.isSuccessful ) {
            return archivedMessageResponse.data.body;
        } else {
            return null;
        }

    }

    async getMessageHistory( id ) {
        let url = Config.getApiDomain() + "/ops-messages/v1/messages/archive/"+id+"/versions";
        let messageHistoryResponse = await this.apiFacade.performGetRequest( url );
        if( messageHistoryResponse.isSuccessful ) {
            return messageHistoryResponse.data.body;
        } else {
            //return null;
            // TODO ILO: EFB-2595: temporary return mock data. When all the work for the versioning is done, return null again
            return [
                {
                    version: '6',
                    createdOn: '2022-08-12 19:23:49Z',
                    user: 'user6@tui.be',
                    UUID: 'fcbd12fe-89c7-477f-a1d6-0364e05a6224'
                },
                {
                    version: '5',
                    createdOn: '2022-08-09 14:59:45Z',
                    user: 'user5@tui.be',
                    UUID: '8e211260-c56d-40de-af43-05b89b8ff1d9'
                },
                {
                    version: '4',
                    createdOn: '2022-08-02 02:15:02Z',
                    user: 'user4@tui.be',
                    UUID: 'fcbd12fe-89c7-477f-a1d6-0364e05a6224'
                },
                {
                    version: '3',
                    createdOn: '2022-07-24 04:12:00Z',
                    user: 'user3@tui.be',
                    UUID: 'fcbd12fe-89c7-477f-a1d6-0364e05a6224'
                },
                {
                    version: '2',
                    createdOn: '2022-07-15 08:36:10Z',
                    user: 'user2@tui.be',
                    UUID: 'fcbd12fe-89c7-477f-a1d6-0364e05a6224'
                },
                {
                    version: '1',
                    createdOn: '2022-06-15 07:25:05Z',
                    user: 'user1@tui.be',
                    UUID: 'fcbd12fe-89c7-477f-a1d6-0364e05a6224'
                },
            ];
        }
    }
};
