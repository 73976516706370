import React from 'react';
import Config from '../../config';
import DateFormatter from "../../helpers/DateFormatter";

import Calendar from 'react-calendar'; // calendar
import 'react-calendar/dist/Calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Predispatch from "../../components/predispatch.component";

export default class Export extends Predispatch {
    constructor( props ) {
        super( props );

        this.handleStartDateSelect = this.handleStartDateSelect.bind( this );
        this.handleEndDateSelect = this.handleEndDateSelect.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.validateForm = this.validateForm.bind( this );
    }

    async componentDidMount() {
        this.setState( { loading: true } );
        await this.validateResourceAccess( this.RESOURCE_OPS_MESSENGER );
        this.setState( { loading: false } );
    }

    // --- Step 1. Select criteria
    handleAOCChange = ( e ) => { // Get all selected AOC values
        let aocs = [];

        let inputElements = document.getElementsByClassName( 'aocBoxes' );
        for( let i=0; inputElements[ i ]; ++i ){
            if( inputElements[ i ].checked ){
                aocs.push( inputElements[ i ].value );
            }
        }
        this.setState( { 'AOC': aocs.join() } );
    }

    handleCriteriaChange = ( e ) => {
        switch( e.target.id ) {
            case 'inputACType':
                this.setState( { 'TYP': e.target.value } );
                break;
            case 'inputArrival':
                this.setState( { 'arr': e.target.value } );
                break;
            case 'inputDeparture':
                this.setState( { 'dep': e.target.value } );
                break;
            default:
            // catch undefiend case
        }
    }

    // --- Step 2. Select start and end date
    handleStartDateSelect( date ) {
        this.setState( { 'start': date } );
    }

    handleEndDateSelect( date = '' ){
        this.setState( { 'end': date } );
    }

    // --- Run after user submits form
    handleSubmit = async () => {
        this.setState( { loading: true } );

        // Format dates
        let strStartDate = null;
        if( this.state.start ) {
            strStartDate = DateFormatter.getSqlDate( this.state.start );
        }
        let strEndDate = null;
        if( this.state.end ) {
            strEndDate = DateFormatter.getSqlDate( this.state.end );
        }

        let formIsValid = this.validateForm();
        if( !formIsValid ) {
            this.toastMessages.toastError( 'Some data in the form is invalid.', 'opsmsg-VALIDATING' );
        } else {
            // Build headers and URL with query params
            let pdfUrl = this.buildPdfUrl( strStartDate, strEndDate );

            // Sending the formdata to the backend
            let presignedS3UrlResponse = await this.apiFacade.performGetRequest( pdfUrl );
            if( presignedS3UrlResponse.isSuccessful ) {
                // API returns a presigned S3 URL
                window.location.href = presignedS3UrlResponse.data;
            } else {
                this.toastMessages.toastError( 'Error while fetching the PDF url.', 'opsmsg-GET' );
            }

        }

        this.setState( { loading: false } );
    };

    validateForm(){
        let formIsValid = true; // will be overwritten if anything is invalid
        
        let startDate       = ( this.state.start ? this.state.start : null );
        let endDate         = ( this.state.end ? this.state.end : null );
        let acType          = ( this.state.TYP ? this.state.TYP.trim() : null );
        let arrival         = ( this.state.arr ? this.state.arr : null );
        let departure       = ( this.state.dep ? this.state.dep : null );
        let delimiter       = ',';

        // Check if dates are valid, end date can not be greater then start date
        if( endDate !== null ){
            let yesterday = new Date( ( new Date() ).valueOf() - 1000*60*60*24 );

            let yesterdayIso = yesterday.toISOString().substr( 0, 10 );
            let endDateIso = endDate.toISOString().substr( 0, 10 );

            // If endDateIso !== yesterdayIso allows for messages to be disabled
            if( endDateIso !== yesterdayIso ){
                if( startDate > endDate ){
                    this.toastMessages.toastError( 'The end date can not be before the start date.', 'opsmsg-DATE' );
                    formIsValid = false;
                }
            }
        }

        // Check regex for all other form fields
        if( acType !== null ){ // TYP
            let acTypes = acType.split( delimiter ).map( i => i.trim() );
            for( let i in acTypes ) {
                let acType = acTypes[ i ]
                if( !/^[a-zA-Z0-9]{1,4}$/.test( acType ) ){
                    this.toastMessages.toastError( 'Aircraft type must be between 1 and 4 characters, numbers allowed.', 'opsmsg-ACTYPE' );
                    formIsValid = false;
                    break;
                }
            }
        }

        if( arrival !== null ){ // arr
            let arrivals = arrival.split( delimiter ).map( i => i.trim() );
            for( let i in arrivals ) {
                let arrival = arrivals[ i ];
                if( !/^[a-zA-Z]{4}$/.test( arrival ) ){
                    this.toastMessages.toastError( 'Arrival value must be 4 characters long.', 'opsmsg-ARRIVAL' );
                    formIsValid = false;
                    break;
                }
            }
        }
        if( departure !== null ){ // arr
            let departures = departure.split( delimiter ).map( i => i.trim() );
            for( let i in departures ) {
                let departure = departures[ i ];
                if( !/^[a-zA-Z]{4}$/.test( departure ) ){
                    this.toastMessages.toastError( 'Departure value must be 4 characters long.', 'opsmsg-DEPARTURE' );
                    formIsValid = false;
                    break;
                }
            }
        }

        return formIsValid;
    };

    buildPdfUrl = ( start, end ) => {
        let axiosUrl = Config.getApiDomain() + '/ops-messages/v1/messages/pdf/url';
        let devider = '?';
        if( this.state.AOC && this.state.AOC !== '' ) {
            axiosUrl += devider + 'airline=' + this.state.AOC;
            devider = '&';
        }
        if( this.state.TYP && this.state.TYP !== '' ) {
            axiosUrl += devider + 'aircraftType=' + this.state.TYP;
            devider = '&';
        }
        if( this.state.dep && this.state.dep !== '' ) {
            axiosUrl += devider + 'departure=' + this.state.dep;
            devider = '&';
        }
        if( this.state.arr && this.state.arr !== '' ) {
            axiosUrl += devider + 'arrival=' + this.state.arr;
            devider = '&';
        }
        if( start && start !== '' ) {
            axiosUrl += devider + 'startDate=' + start;
            devider = '&';
        }
        if( end && end !== '' ) {
            axiosUrl += devider + 'endDate=' + end;
            devider = '&';
        }

        return axiosUrl;
    }

    render() {
        let self = this;
        const aocs = [ 'JAF', 'TUI', 'TFL', 'BLX', 'TOM' ];

        return (

            <div className="container ops-messenger-container">
                <div className="row">
                    <div className="col-lg-12">
                        { self.state.loading &&
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        }

                        <h3 className="mt-5 mb-5">Export messages in one PDF</h3>

                        <form onSubmit={ async e => {
                            e.preventDefault();
                            await this.handleSubmit();
                        } }>
                            <div className="row">
                                {/* --- LEFT --- */}
                                <div className="col-lg-5 col-md-6 col-sm-12">

                                    <div className="horizontal-form-split"><span>1. Limit/filter messages</span></div>

                                    <div className="form-group form-group-flex">
                                        { aocs.map( function( aoc, key ){
                                            return <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input aocBoxes" name="aocBoxes[]"
                                                       id={key} value={aoc}
                                                       onChange={ self.handleAOCChange } />
                                                <label className="custom-control-label" htmlFor={key}>{aoc}</label>
                                            </div>;
                                        })}
                                    </div>

                                    <div className="form-group form-group-category" id="groupACType">
                                        <input type="text" className="form-control" id="inputACType" value={ this.state.TYP } aria-describedby="aircraftTypeHelp" placeholder="Aircraft Type"
                                               onChange={ this.handleCriteriaChange } />
                                        <small id="aircraftTypeHelp" className="form-text text-muted">Ex: B738 or B78 for all 787-fleet.</small>
                                        <small id="aircraftTypeHelp" className="form-text text-muted">Possible types are E190, E295, B737, B738, B38M, B763, B788, B789.</small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupDeparture">
                                        <input type="text" className="form-control" id="inputDeparture" value={ this.state.dep } aria-describedby="departureHelp" placeholder="Departure airport ( ICAO )"
                                               onChange={ this.handleCriteriaChange } />
                                        <small id="departureHelp" className="form-text text-muted">Ex: EDDF or EBBR</small>
                                    </div>

                                    <div className="form-group form-group-category" id="groupArrival">
                                        <input type="text" className="form-control" id="inputArrival" value={ this.state.arr } aria-describedby="arrivalHelp" placeholder="Arrival airport ( ICAO )"
                                               onChange={ this.handleCriteriaChange } />
                                        <small id="arrivalHelp" className="form-text text-muted">Ex: EDDF or EBBR</small>
                                    </div>

                                </div>

                                {/* --- RIGHT --- */}
                                <div className="col-lg-7 col-md-6 col-sm-12">
                                    <div className="horizontal-form-split"><span>2. Select start and/or end date ( leave blank to see all )</span></div>

                                    <div className="form-group form-group-double-calendar">
                                        <Calendar
                                            onChange={ this.handleStartDateSelect }
                                            value={ this.state.start }
                                        />
                                        <Calendar
                                            onChange={ this.handleEndDateSelect }
                                            value={ this.state.end }
                                        />
                                    </div>
                                    { this.state.end &&
                                    <button onClick={ e => { e.preventDefault(); this.handleEndDateSelect(); } }
                                            className="btn btn-md btn-warning btn-block mb-2 mt-2">Clear end date ( set to UFN )</button>
                                    }
                                </div>

                                {/* --- FULL WIDTH --- */}
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="button-group-flex">
                                        <button id="btnUpload"
                                                className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2 mt-2"
                                                type="submit">
                                            Export
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
