import React from 'react';
import Predispatch from "../../components/predispatch.component";

import Config from "../../config";
import FileList from "./filelist.component";
import FlightkeysList from "./flightkeyslist.component";
import FlightkeysAudit from "./flightkeysaudit.component";
import EurocontrolTimes from "./eurocontroltimes.component";
import Flights from "../services/Flights";
import DateFormatter from "../../helpers/DateFormatter";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import {Button} from "react-bootstrap";
import FlightIds from "../services/FlightIds";
import RetriggerFlightId from "../services/RetriggerFlightId";

export default class Flight extends Predispatch {
    constructor( props ) {
        super( props );

        this.flights = new Flights();

        this.state = { isSearchFormOpen: false };
        this.state = { isEditFormOpen: false };

        this.flightIds = new FlightIds();
        this.retriggerFlightId = new RetriggerFlightId();
    }

     async componentDidMount() {
         this.setState( { loading: true } );

         let hasAccess = await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
         if( hasAccess ) {
             // You may call setState() immediately in componentDidMount(). It will trigger an extra rendering,
             // but it will happen before the browser updates the screen. This guarantees that even though the
             // render() will be called twice in this case, the user won’t see the intermediate state.

             // Retrieve afId from url/props and fetch files
             let afId = this.props.match.params[ 'afid' ];

             this.setState({
                 // Store logged user in state
                 'creator': localStorage.getItem( "user" ),
                 'afId': afId
             });

             await this.fetchFlightKeysFilesForAfId( afId );
             await this.fetchFlightKeysAuditTrailsForAfId( afId );
             await this.fetchEurocontrolTimesForAfId( afId );
             await this.fetchFlightKeysData( afId );
             this.makeVisualChangesForNegAfId( afId );
             await this.fetchReturnedFiles( afId );
         }

         this.setState( { loading: false } );
     }

    handleOpenSearchForm = () => {
        if( this.props.match.params[ 'afid' ] < 0 ) {
            this.setState( { "isSearchFormOpen": true } );
            this.handleCloseEditForm();
        }
    };

    handleRetriggerEvent = async () => {
        let flightId = this.props.match.params[ 'afid' ];

        let response = await this.retriggerFlightId.retriggerFlight( flightId );
        if( !response ){
            this.toastMessages.toastError( 'Could not retrigger the flight process.', 'returndata-GET' );
        } else {
            this.toastMessages.toastSuccess( 'Successfully retriggered the flight process.', 'returndata-GET' );
        }
    };

    handleCloseSearchForm = () => {
        this.setState( { "isSearchFormOpen": false } );
    };

    handleOpenEditForm = () => {
        this.setState( { "isEditFormOpen": true } );
        this.handleCloseSearchForm();
    };

    handleCloseEditForm = () => {
        this.setState( { "isEditFormOpen": false } );
    };

    handleSearchFlight = async () => {
        this.setState( { loading: true } );

        let flightId = this.state.flightId;
        if( !flightId ) {
            this.toastMessages.toastValidation( 'Flight ID may not be empty', 'flightid' );
        } else {
            if( !/^[0-9]{8,9}$/.test( flightId ) ){
                this.toastMessages.toastValidation( 'Flight ID is invalid', 'flightid' );
            }

            let hasAccess = await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
            if( hasAccess ) {
                let flightData = await this.flights.getTibcoFlightByFlightId( flightId );
                if( !flightData ) {
                    this.toastMessages.toastError( 'Could not retrieve flights.', 'tibco-GET' );
                } else {
                    this.populateEditDialog( flightData );
                    this.handleOpenEditForm();
                }
            }
        }

        this.setState( { loading: false } );
    };

    handleSaveFlightId = async () => {
        this.setState( { loading: true } );

        let hasAccess = await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
        if( hasAccess ) {
            let negAfId = this.props.match.params[ 'afid' ];
            let mapFlightIdResponse = await this.flightIds.mapFlightId( negAfId, this.state.tibcoFlightId );
            if( !mapFlightIdResponse ) {
                this.toastMessages.toastError( 'Could not map flight ID', 'returndata-POST' );
                this.setState( { loading: false } );
            } else {
                this.toastMessages.toastSuccess( 'Successfully mapped flight ID', 'returndata-POST' );
                let self = this;

                window.location.replace( '/fa/home' );
                setTimeout( function() {
                    self.handleCloseEditForm();
                    window.location.replace( '/' );
                    window.location.replace( '/fa/flight/' + negAfId );
                    self.setState( { loading: false } );
                }, 1000 );
            }
        }
    };

    handleCriteriaChange = ( e ) => {
        switch( e.target.id ) {
            case 'txtFlightId':
                this.setState( { 'flightId': e.target.value } );
                break;
            default:
            // catch undefiend case
        }
    }

    populateEditDialog( flightData ) {
        let flightInfo = flightData.FlightInformation;
        let flightId   = flightData.FlightID;

        this.setState( {
            'tibcoFlightId': flightId,
            'tibcoFlightPrefix': flightInfo.FlightPrefix,
            'tibcoFlightNumber': flightInfo.FlightNumber,
            'tibcoFlightDeparture': flightInfo.FlightDeparture,
            'tibcoFlightDestination': flightInfo.FlightDestination,
            'tibcoFlightOwner': flightInfo.FlightOwner
        } );
    }

    makeVisualChangesForNegAfId = async ( afId ) => {
        // Fill table with flights for today ( default behaviour, without extra params before submitting form )
        let flightIds = await this.flightIds.getFlightIds();
        if( !flightIds ){
            this.toastMessages.toastError( 'Could not retrieve flightId.', 'returndata-GET' );
        } else {
            for( let i in flightIds ) {
                if( flightIds[ i ].flightId === afId ) {
                    this.setState( { flightIds:  flightIds[ i ] } );
                    break;
                }
            }
        }
    };

    fetchFlightKeysFilesForAfId = async ( afId ) => {
        let self = this;

        let fileResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/flightkeys/v1/flights/' + afId, [404] );
        if( fileResponse.isSuccessful ) {
            if( fileResponse.status !== 404 ){
                let flightPlanId = fileResponse.data.body.flightPlanId;

                let numberOfFiles = parseInt( flightPlanId.slice( -1 ) ); // integer
                let downloadUrls = [];
                let promises = [];

                for( let i = 1; i < numberOfFiles + 1; i++ ){
                    let filename = 'eff-dat-' + i;
                    let axiosPromise = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/flightkeys/v1/flights/' + afId + '/briefing/download/' + filename + '.zip/url' );
                    promises.push( axiosPromise );
                }

                let promiseResponses = await Promise.all( promises );
                for( let i in promiseResponses ){
                    let response = promiseResponses[ i ];

                    if( response.isSuccessful ) {
                        let number = parseInt( i ) + 1;
                        let filename = 'eff-dat-' + number;
                        downloadUrls.push( { name: filename, url: response.data } );
                    } else {
                        this.toastMessages.toastError( 'Could not download flightkeys file.', 'flightkeys-GET' );
                    }

                }
                this.setState( { 'flightkeysFiles': downloadUrls } );
            }
        } else {
            this.toastMessages.toastError( 'Could not retrieve latest flightkeys version.', 'flightkeys-GET' );
        }

        self.setState( { loading: false } );

    }

    async fetchEurocontrolTimesForAfId  ( afId )  {
        let eurocontrolTimesForAfIdResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/eurocontrol/v1/flightdata/flights/' + afId + '/audit' );
        if( eurocontrolTimesForAfIdResponse.isSuccessful ) {
            this.setState( { "eurocontrolTimes": eurocontrolTimesForAfIdResponse.data.body.times } ); // array of audit trails
            return eurocontrolTimesForAfIdResponse.data.body; // array of audit trails
        } else {
            this.setState( { "eurocontrolTimes": [] } );
            this.toastMessages.toastError( 'Could not retrieve eurocontrol times.', 'eurocontrol-GET' );
        }

    }

    async fetchFlightKeysAuditTrailsForAfId  ( afId )  {

        let flightKeysAuditTrailsForAfIdResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/flightkeys/v1/flights/' + afId + '/audit', [404] );
        if( flightKeysAuditTrailsForAfIdResponse.isSuccessful ) {
            if( flightKeysAuditTrailsForAfIdResponse.status === 404 ){
                this.setState( { "flightkeysAuditTrails": [] } ); // array of audit trails
            } else {
                this.setState( { "flightkeysAuditTrails": flightKeysAuditTrailsForAfIdResponse.data.body } ); // array of audit trails
            }
            return flightKeysAuditTrailsForAfIdResponse.data.body; // array of audit trails
        } else {
            this.setState( { "flightkeysAuditTrails": [] } );
            this.toastMessages.toastError( 'Could not retrieve flightkeys audit trails.', 'flightkeys-GET' );
        }

    }

    async fetchFlightKeysData( afId ){
        let self = this;

        let flightKeysDataResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/flightkeys/v1/flights/' + afId );
        if( flightKeysDataResponse.isSuccessful ){
            self.setState( { "flightDate": flightKeysDataResponse.data.body.flightOriginDate } );
            self.setState( { "flightNr": flightKeysDataResponse.data.body.idpsFlightNumber } );
            self.setState( { "flightDep": flightKeysDataResponse.data.body.departureAirport } );
            self.setState( { "flightDest": flightKeysDataResponse.data.body.arrivalAirport } );
            self.setState( { "flightStd": flightKeysDataResponse.data.body.scheduledTimeOfDeparture } );
            self.setState( { "flightSta": flightKeysDataResponse.data.body.scheduledTimeOfArrival } );
            self.setState( { "aircraftIcaoType": flightKeysDataResponse.data.body.aircraftIcaoType } );
            self.setState( { "aircraftRegistration": flightKeysDataResponse.data.body.aircraftRegistration } );
            self.setState( { "afId": flightKeysDataResponse.data.body.af_id } );
        } else {
            this.toastMessages.toastError( 'Could not retrieve flightkeys data.', 'flightkeys-GET' );
        }

        self.setState( { loading: false } );

    }

    async fetchReturnedFiles( afId ){
        let returnJson = await this.fetchReturnJsonFilesList( afId );
        let fuelSignature = await this.fetchFuelSignatureFilesList( afId );
        let filledCbp = await this.fetchFilledCbpFilesList( afId );
        let flightDocs = await this.fetchFlightDocsFilesList( afId );

        let returnedFiles = await this.combineFilesInArray( returnJson, fuelSignature, filledCbp, flightDocs );
        this.setState( { 'returnedFiles': returnedFiles } );
    }

    async fetchReturnJsonFilesList( afId ){
        this.state.disableRetrigger = true;

        let returnJsonFilesListResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/returndata/v1/flights/' + afId + '/returnjson/files', [404] );
        if( returnJsonFilesListResponse.isSuccessful ) {
            if( returnJsonFilesListResponse.status === 404 ) {
                return [];
            } else {
                let arrFiles = [];
                for( let i in returnJsonFilesListResponse.data.body ){
                    this.state.disableRetrigger = false;
                    let item = returnJsonFilesListResponse.data.body[ i ];
                    arrFiles.push( item );
                }
                return arrFiles;
            }
        } else {
            this.toastMessages.toastError( 'Could not retrieve return json files.', 'returndata-GET' );
        }

    }

    async fetchFuelSignatureFilesList( afId ){

        let fuelSignatureFilesListResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/returndata/v1/flights/' + afId + '/fuelsignature/files', [404] );
        if( fuelSignatureFilesListResponse.isSuccessful ) {
            if( fuelSignatureFilesListResponse.status === 404 ) {
                return [];
            } else {
                let arrFiles = [];
                for (let i in fuelSignatureFilesListResponse.data.body) {
                    let item = fuelSignatureFilesListResponse.data.body[i];
                    arrFiles.push(item);
                }
                return arrFiles;
            }
        } else {
            this.toastMessages.toastError( 'Could not retrieve fuelsignature files.', 'returndata-GET' );
        }

    }

    async fetchFilledCbpFilesList( afId ){

        let filledCbpFilesListResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/returndata/v1/flights/' + afId + '/filledcbp/files', [404] );
        if( filledCbpFilesListResponse.isSuccessful ){
            if( filledCbpFilesListResponse.status === 404 ) {
                return [];
            } else {
                let arrFiles = [];
                for( let i in filledCbpFilesListResponse.data.body ){
                    let item = filledCbpFilesListResponse.data.body[ i ];
                    arrFiles.push( item );
                }
                return arrFiles;
            }
        } else {
            this.toastMessages.toastError( 'Could not retrieve cbp files.', 'returndata-GET' );
        }

    }

    async fetchFlightDocsFilesList( afId ){

        let flightDocsFilesListResponse = await this.apiFacade.performGetRequest( Config.getApiDomain() + '/returndata/v1/flights/' + afId + '/flightdocs', [404] );
        if( flightDocsFilesListResponse.isSuccessful ) {
            if( flightDocsFilesListResponse.status === 404 ) {
                return [];
            } else {
                let arrFiles = [];
                for (let i in flightDocsFilesListResponse.data.body) {
                    let item = flightDocsFilesListResponse.data.body[i];
                    arrFiles.push(item);
                }
                return arrFiles;
            }
        } else {
            this.toastMessages.toastError( 'Could not retrieve flightdoc files.', 'returndata-GET' );
        }

    }

    async combineFilesInArray( returnJson, fuelSignature, filledCbp, flightDocs ){
        let returnedFiles = [];

        if( returnJson ){
            for( let i in returnJson ){
                // Extract extension from filename
                let item = returnJson[i];
                let filename = returnJson[i].objectName;
                let splitFilename = filename.split( '.' );
                item.filetype = splitFilename[ splitFilename.length - 1 ];

                // Add to array
                returnedFiles.push( item );
            }
        }

        if( fuelSignature ){
            for( let i in fuelSignature ){
                // Extract extension from filename
                let item = fuelSignature[i];
                let filename = fuelSignature[i].objectName;
                let splitFilename = filename.split( '.' );
                item.filetype = splitFilename[ splitFilename.length - 1 ];

                // Add to array
                returnedFiles.push( item );
            }
        }

        if( filledCbp ){
            for( let i in filledCbp ){
                // Extract extension from filename
                let item = filledCbp[i];
                let filename = filledCbp[i].objectName;
                let splitFilename = filename.split( '.' );
                item.filetype = splitFilename[ splitFilename.length - 1 ];

                // Add to array
                returnedFiles.push( item );
            }
        }

        if( flightDocs ){
            for( let i in flightDocs ){
                // Extract extension from filename
                let item = flightDocs[i];

                let filename = flightDocs[i].objectName;
                let splitFilename = filename.split( '.' );
                let subtypeAndName = splitFilename[0].split( '/' );
                let subtype = subtypeAndName[1].split( '_' );
                item.filetype = splitFilename[ splitFilename.length - 1 ];
                item.dataType = subtype[0].charAt(0).toUpperCase() + subtype[0].slice(1); // Capitalize word

                // In order not to change the api call /flightdocs/notoccargo after the comment of Jan Heuer, the visual change is done here
                if( item.dataType === 'Notoccargo' ) {
                    item.dataType = 'Notoc_Cargo'
                }

                // Add to array
                returnedFiles.push( item );
            }
        }

        return returnedFiles;
    }

    render() {
        let self = this;

        // Flight data for detail page
        let afId = self.state.afId;
        let flightDate = self.state.flightDate;
        let flightNr = self.state.flightNr;
        let flightDep = self.state.flightDep;
        let flightDest = self.state.flightDest;
        let flightStd = self.state.flightStd;
        if( self.state.flightStd ){
            flightStd = DateFormatter.getReadableDateTimeFromIso( self.state.flightStd );
        }
        let flightSta = self.state.flightSta;
        if( self.state.flightSta ){
            flightSta = DateFormatter.getReadableDateTimeFromIso( self.state.flightSta );
        }
        let aircraftIcaoType = self.state.aircraftIcaoType;
        let aircraftRegistration = self.state.aircraftRegistration;

        let flightIdDisplay = 0;
        if ( afId > 0 ) {
            flightIdDisplay = afId;
        } else {
            if( this.state.flightIds && this.state.flightIds.idpsFlightId ) {
                flightIdDisplay = afId + " / " + this.state.flightIds.idpsFlightId;
            } else {
                flightIdDisplay = afId;
            }
        }

        let isEditHidden = true;
        if( afId < 0 && this.state.flightIds && !this.state.flightIds.idpsFlightId ) {
            isEditHidden = false;
        }

        return (
            <div className="container ofp-return-data-form-container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-3 mb-3">Detailed information</h3>

                        { self.state.loading &&
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        }

                        <div className="main-info-wrapper">
                            <div className="main-info-flightinfo">
                                <h5 className="h5">Flight info</h5>
                                <p>Flight ID: <b>{ flightIdDisplay }</b></p>
                                <p>Flight date: <b>{ flightDate }</b></p>
                                <p>Flight nr: <b>{ flightNr }</b></p>
                                <p>Departure - destination: <b>{ flightDep + "-" + flightDest }</b></p>
                                <p>Scheduled Time of Departure: <b>{ flightStd }</b></p>
                                <p>Scheduled Time of Arrival: <b>{ flightSta }</b></p>
                                <p>Aircraft ICAO type: <b>{ aircraftIcaoType }</b></p>
                                <p>Aircraft registration: <b>{ aircraftRegistration }</b></p>
                            </div>

                            <div className="main-info-right">
                                <div className="main-info-flightkeys">
                                    <h5 className="h5">Flightkeys files</h5>
                                    { !self.state.flightkeysFiles &&
                                    <p>Fetching files...</p>
                                    }

                                    { self.state.flightkeysFiles && self.state.flightkeysFiles.length === 0 &&
                                    <p><i>No flightkeys available for this flight.</i></p>
                                    }

                                    { self.state.flightkeysFiles && self.state.flightkeysFiles.length !== 0 &&
                                    <FlightkeysList list={ self.state.flightkeysFiles } afId={ self.state.afId } />
                                    }
                                </div>


                                <div className="main-info-actions">
                                    <h5 className="h5">Actions</h5>

                                    <p><button hidden={ this.state.disableRetrigger } className="btn btn-outline-primary" onClick={this.handleRetriggerEvent}>
                                        Retrigger flight data processing
                                    </button></p>

                                    <p><button hidden={ isEditHidden } className="btn btn-outline-primary" onClick={this.handleOpenSearchForm}>
                                        Map flight ID
                                    </button></p>


                                    {/* ***** Dialog to input correct AF_ID and request Tibco Data ***** */}
                                    <Dialog open={this.state.isSearchFormOpen}
                                            onClose={this.handleCloseSearchForm}
                                            aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">Current Flight ID: {afId}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Search for IDPS data
                                            </DialogContentText>
                                            <TextField
                                                onChange={ this.handleCriteriaChange }
                                                autoFocus
                                                margin="dense"
                                                id="txtFlightId"
                                                label="Flight ID"
                                                fullWidth
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleSearchFlight} color="primary">
                                                Search
                                            </Button>
                                            <Button onClick={this.handleCloseSearchForm} color="primary">
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* ***** Dialog to display Tibco data and confirm AF_ID mapping ***** */}
                                    <Dialog open={this.state.isEditFormOpen}
                                            onClose={this.handleCloseEditForm}
                                            aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">Is this the correct IDPS flight to be mapped?</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <div className="row">
                                                    <div className="col-lg-6">Flight ID</div>
                                                    <div className="col-lg-6"><b>{this.state.tibcoFlightId}</b></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">Flight Prefix</div>
                                                    <div className="col-lg-6">{this.state.tibcoFlightPrefix}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6"> Flight Number</div>
                                                    <div className="col-lg-6">{this.state.tibcoFlightNumber}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">Flight Departure</div>
                                                    <div className="col-lg-6">{this.state.tibcoFlightDeparture}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">Flight Destination</div>
                                                    <div className="col-lg-6">{this.state.tibcoFlightDestination}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">Flight Owner</div>
                                                    <div className="col-lg-6">{this.state.tibcoFlightOwner}</div>
                                                </div>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleSaveFlightId} color="primary">
                                                Yes
                                            </Button>
                                            <Button onClick={this.handleCloseEditForm} color="primary">
                                                No
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* ***** END of Dialogs ***** */}

                                </div>
                            </div>

                        </div>

                        <h5 className="h5">Eurocontrol times</h5>
                        { !self.state.eurocontrolTimes &&
                            <p>Fetching eurocontrol times...</p>
                        }

                        { self.state.eurocontrolTimes && self.state.eurocontrolTimes.length === 0 &&
                            <p><i>No eurocontrol times found for this flight.</i></p>
                        }

                        { self.state.eurocontrolTimes && self.state.eurocontrolTimes.length !== 0 &&
                            <EurocontrolTimes list={ self.state.eurocontrolTimes } afId={ self.state.afId } />
                        }

                        <h5 className="h5">Flightkeys audit trails</h5>
                            { !self.state.flightkeysAuditTrails &&
                                <p>Fetching trails...</p>
                            }

                            { self.state.flightkeysAuditTrails && self.state.flightkeysAuditTrails.length === 0 &&
                                <p><i>No audit trails found for this flight.</i></p>
                            }

                            { self.state.flightkeysAuditTrails && self.state.flightkeysAuditTrails.length !== 0 &&
                                <FlightkeysAudit list={ self.state.flightkeysAuditTrails } afId={ self.state.afId } />
                            }

                        <h5 className="h5">Returned files</h5>
                        <table className="table">
                            <thead></thead>
                                { !self.state.returnedFiles &&
                                    <p>Fetching files...</p>
                                }

                                { self.state.returnedFiles && self.state.returnedFiles.length === 0 &&
                                    <p><i>No returned files available for this flight.</i></p>
                                }

                                { self.state.returnedFiles && self.state.returnedFiles.length !== 0 &&
                                    <FileList files={ self.state.returnedFiles } afId={ self.state.afId } />
                                }
                        </table>

                    </div>
                </div>
            </div>
        )
    }
}