import React from 'react';
import {Link} from "react-router-dom";
import Predispatch from "../../components/predispatch.component";


export default class Home extends Predispatch {

    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_GENDEC );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Gendec</h3>
                    </div>
                    <div className="col-lg-6">
                        <h4>Welcome!</h4>
                        <div className="row">
                            <Link to="/gd/generate" className="nav-link">Generate Gendec</Link>
                        </div>
                        <div className="row">
                            <Link to="/gd/search" className="nav-link">Search</Link>
                        </div>
                        <p className="mt-5">If you run into any problems, please have a look <Link
                            to="/gd/help">here</Link>.</p>
                        <p>Please send feedback to <a
                            href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>.</p>
                    </div>
                    <div className="col-lg-6">
                        <h4>Changelog</h4>
                        <div>
                            <h5>2021-03-02</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Release of Gendec</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}