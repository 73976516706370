import React from 'react';
import {Link} from "react-router-dom";
import Predispatch from "../../components/predispatch.component";

import $ from "jquery";
import Calendar from 'react-calendar'; // calendar
import 'react-calendar/dist/Calendar.css';
import DateFormatter from "../../helpers/DateFormatter";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Flights from '../services/Flights';
import { MODULES } from '@aviation/efb-react-sdk/main/Authorisation/authoriser';
import ToastMessages from '@aviation/efb-react-sdk/main/helpers/toastMessages';
import TokenHelper from '@aviation/efb-react-sdk/main/Authorisation/tokenHelper';

export default class Search extends Predispatch {
    constructor( props ) {
        super( props );

        this.toastMessages = new ToastMessages();
        this.tokenHelper = new TokenHelper();
        this.flights = new Flights();

        this.handleStartDateSelect = this.handleStartDateSelect.bind( this );
        this.handleEndDateSelect = this.handleEndDateSelect.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.validateForm = this.validateForm.bind( this );
    }

    async componentDidMount() {
        let hasAccess = await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
        if( hasAccess ){
            this.toggleForm();

            // Store logged user in state
            this.setState( { 'creator': localStorage.getItem( 'user' ) } );

            // Fill table with flights for today ( default behaviour, without extra params before submitting form )
            this.setState( { loading: true } );
            await this.callFlightsAndListInTable();
            this.setState( { loading: false } );
        }
    }

    callFlightsAndListInTable = async ( formParams = null ) => {
        let user = await this.tokenHelper.getCurrentUser();
        if( !user ){
            this.toastMessages.toastValidation( 'Invalid user detected.', 'user' );
        } else {
            // console.log(user);
            // user.roles = [];
            // user.roles[0] = "FlightArchive-UK";
            // user.roles[1] = "FlightArchive-DE";

            let airlinesToFilter = this.authoriser.getAirlinesByUserAndModule( user, MODULES[ 'FA' ] );

            // Filter flights on user-allowed airlines
            let flightParams = { 'airlinesToFilter': airlinesToFilter };

            // OPTIONAL | Add filters from the user form ( if it has been submitted )
            if( formParams ){
                if( formParams.departure ){
                    flightParams.departure = formParams.departure;
                }
                if( formParams.destination ){
                    flightParams.destination = formParams.destination;
                }
                if( formParams.flightNr ){
                    flightParams.flightNr = formParams.flightNr;
                }
                if( formParams.startDate ){
                    flightParams.startDate = formParams.startDate;
                }
                if( formParams.endDate ){
                    flightParams.endDate = formParams.endDate;
                }
            }

            // Filtering parameters from the form can be added in getFlightsByParams
            let flights = await this.flights.getFlightsByParams( flightParams ); // should always return array
            if( flights === [] ){
                this.toastMessages.toastError( 'Could not retrieve flights.', 'flightkeys-GET' );
            }
            this.setState( { 'flights': flights } );
        }
    }
    
    capitalizeInput = ( e ) => {
        e.target.value = e.target.value.toUpperCase();
    }

    handleInputChange = ( e ) => {
        switch( e.target.id ) {
            case 'inputCountry':
                this.setState( { 'country': e.target.value } );
                break;
            case 'inputFlightNr':
                this.setState( { 'flightNr': e.target.value } );
                break;
            case 'inputDeparture':
                this.setState( { 'departure': e.target.value } );
                break;
            case 'inputDestination':
                this.setState( { 'destination': e.target.value } );
                break;
            default:
            // catch undefined case
        }
    }

    handleStartDateSelect( date ) {
        this.setState( { 'start': date } );
    }

    handleEndDateSelect( date = '' ){
        this.setState( { 'end': date } );
    }

    handleSubmit = async () => {
        this.setState( { loading: true } );

        let strStartDate = null;
        if( this.state.start ) {
            strStartDate = DateFormatter.getSqlDate( this.state.start );
        }
        let strEndDate = null;
        if( this.state.end ) {
            strEndDate = DateFormatter.getSqlDate( this.state.end );
        }

        // Validate the rest of the fields
        let isValid = await this.validateForm();
        if( !isValid ){
            this.toastMessages.toastError( 'Some data in the form is invalid..', 'validating' );
        } else {
            // If form is completely validated, start axios call
            let axiosData = {
                "departure": ( this.state.departure ? this.state.departure.toUpperCase().trim() : null ),
                "destination": ( this.state.destination ? this.state.destination.toUpperCase().trim() : null ),
                "flightNr": ( this.state.flightNr ? this.state.flightNr.toUpperCase().trim() : null ),
                "startDate": ( strStartDate ),
                "endDate": ( strEndDate ),
            };

            await this.callFlightsAndListInTable( axiosData );

            $( '#flightsForm' ).slideToggle();
            $( '.fa-chevron-up' ).toggleClass( 'fa-chevron-down' ).toggleClass( 'fa-chevron-up' );

            this.setState( { loading: false } );
        }
    };

    async validateForm(){
        let formIsValid = true; // will be overwritten if anything is invalid

        let startDate       = ( this.state.start ? this.state.start : null );
        let endDate         = ( this.state.end ? this.state.end : null );
        let flightNr        = ( this.state.flightNr ? this.state.flightNr.trim() : null );
        let departure     = ( this.state.departure ? this.state.departure.trim() : null );
        let destination    = ( this.state.destination ? this.state.destination.trim() : null );

        // Check if dates are valid, end date can not be greater then start date
        if( endDate !== null ){

            if( startDate == null ){
                this.toastMessages.toastValidation( 'No start date selected.', 'date' );
                formIsValid = false;
            }
            if( startDate > endDate ){
                this.toastMessages.toastValidation( 'The end date can not be before the start date.', 'date' );
                formIsValid = false;
            }
        }

        // Check regex for all other form fields
        if( departure !== null ){
            if( !/^[a-zA-Z]{2}$/.test( departure ) && !/^[a-zA-Z]{4}$/.test( departure ) ){
                this.toastMessages.toastValidation( 'Departure value can be 2 or 4 characters.', 'departure' );
                formIsValid = false;
            }
        }

        if( destination !== null ){
            if( !/^[a-zA-Z]{2}$/.test( destination ) && !/^[a-zA-Z]{4}$/.test( destination ) ){
                this.toastMessages.toastValidation( 'Destination value can be 2 or 4 characters.', 'destination' );
                formIsValid = false;
            }
        }

        if( flightNr !== null ){
            if( !/^-{0,1}[a-zA-Z0-9]{2,12}$/.test( flightNr ) || flightNr.length > 13 ){
                this.toastMessages.toastValidation( 'Flight number must be between 2 and 13 characters.', 'flightnr' );
                formIsValid = false;
            }
        }

        return formIsValid;
    };

    toggleForm = () => {
        $( '.fa-chevron-up' ).click( function(){
            $( '#flightsForm' ).slideToggle();
            $( this ).toggleClass( 'fa-chevron-down' ).toggleClass( 'fa-chevron-up' );
        });
    }

    buildLink = ( cell, row, self ) => {
        // Render/return button to open modal
        return (
            <Link to={"/fa/flight/" + row.af_id} className="btn btn-primary btn-white-text">View details</Link>
        );
    }

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    };

    render() {

        const options = {
            sizePerPageList: [ 10, 25, 50 ],
            sizePerPage : 50
        };

        return (
            <div className="container flight-archive-form-container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="title-with-toggle mt-5 mb-5">Filter flights <span class="fa fa-chevron-up"></span></h3>

                        { this.state.loading &&
                            <div className="spinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        }

                        <form id="flightsForm" onSubmit={ e => { e.preventDefault(); this.handleSubmit(); } }>
                
                            {/* --- LEFT --- */}
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-12">
                
                                    <div className="horizontal-form-split mt-4"><span>Filter flights by parameters</span></div>
                
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="inputFlightNr"
                                               placeholder="Flight nr" aria-describedby="flightNrHelp"
                                               onChange={ this.handleInputChange }
                                               onKeyUp={ this.capitalizeInput }  />
                                        <small id="flightNrHelp" className="form-text text-muted">Ex: X31234 for flight #1234.</small>
                                    </div>
                
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="inputDeparture"
                                               placeholder="Departure" aria-describedby="departureHelp"
                                               onChange={ this.handleInputChange }
                                               onKeyUp={ this.capitalizeInput }  />
                                       <small id="departureHelp" className="form-text text-muted">Ex: EDDF for Frankfurt.</small>
                                    </div>
                
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="inputDestination"
                                               placeholder="Destination" aria-describedby="destinationHelp"
                                               onChange={ this.handleInputChange }
                                               onKeyUp={ this.capitalizeInput }  />
                                       <small id="destinationHelp" className="form-text text-muted">Ex: EDDF for Frankfurt.</small>
                                    </div>
                                </div>
                
                                {/* --- RIGHT --- */}
                                <div className="col-lg-7 col-md-6 col-sm-12">

                                    <div className="horizontal-form-split mt-4"><span>Filter flights between dates</span></div>

                                    <div className="form-group form-group-double-calendar">
                                        <Calendar
                                            onChange={ this.handleStartDateSelect }
                                            value={ this.state.start }
                                        />
                                        <Calendar
                                            onChange={ this.handleEndDateSelect }
                                            value={ this.state.end }
                                        />
                                    </div>
                                </div>
                            </div>
                
                            {/* --- FULL WIDTH --- */}
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="button-group-flex">
                                        <button id="btnUpload" type="submit"
                                                className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2 mt-2">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Results</h3>

                        { this.state.flights &&
                            <BootstrapTable
                                // wrapperClasses="table-responsive"
                                // responsive='md'
                                options={ options }
                                data={ this.state.flights }
                                version='4'
                                pagination={ true }
                                search={ true }>
                                <TableHeaderColumn isKey dataField='af_id' dataSort={ true } hidden={true} >AfId</TableHeaderColumn>
                                <TableHeaderColumn dataField='fufi' dataSort={ true } hidden={true} >Fufi</TableHeaderColumn>
                                <TableHeaderColumn dataField='flightPlanId' dataSort={ true } hidden={true} >Flight Plan Id</TableHeaderColumn>
                                <TableHeaderColumn dataField='aircraftRegistration' hidden={true} >AC reg</TableHeaderColumn>
                                <TableHeaderColumn dataField='aircraftIcaoType' hidden={true} >AC type</TableHeaderColumn>
                                <TableHeaderColumn dataField='idpsFlightNumber' dataSort={ true }>Flight</TableHeaderColumn>
                                <TableHeaderColumn dataField='scheduledTimeOfDeparture' dataSort={ true } dataFormat={ this.isoDateFormat }>STD</TableHeaderColumn>
                                <TableHeaderColumn dataField='departureAirport' dataSort={ true }>Departure</TableHeaderColumn>
                                <TableHeaderColumn dataField='arrivalAirport' dataSort={ true }>Arrival</TableHeaderColumn>
                                <TableHeaderColumn dataField='af_id' dataSort={ true } dataFormat={ this.buildLink } formatExtraData={ this }>File</TableHeaderColumn>
                            </BootstrapTable>
                        }

                        { !this.state.flights &&
                            // <p>Please submit the form above to see results.</p>
                            <p>API call failed.</p>
                        }

                    </div>
                </div>
            </div>
        )
    }
}