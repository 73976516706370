import React from 'react';
import Config from '../config';
import Predispatch from "./../components/predispatch.component";

export default class Switcher extends Predispatch {

    async componentDidMount() {
        await this.setUser();
    }

    async setUser() {
        let isAllowedFA = await this.authoriser.isUserAllowed( this.RESOURCE_FLIGHT_ARCHIVE, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
        let isAllowedOPS = await this.authoriser.isUserAllowed( this.RESOURCE_OPS_MESSENGER, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
        let isAllowedGD = await this.authoriser.isUserAllowed( this.RESOURCE_GENDEC, Config.getRegion(), Config.getUserPoolId(), Config.getEnvironment() );
        if( isAllowedFA || isAllowedOPS || isAllowedGD ){
            let user = await this.tokenHelper.getCurrentUser();
            if( user ){
                this.setState( { 'user': user } );
            }
        }
    }

    activateOpsMessenger() {
       window.location.replace("/ops/home");
    }

    activateFaReturnData() {
        window.location.replace("/fa/home");
    }

    activateGendec() {
        window.location.replace("/gd/home");
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7">
                                <h3 className="mt-5 mb-5">Choose your portal</h3>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_OPS_MESSENGER, Config.getEnvironment() ) ?
                                <div className="col-lg-6">
                                    <button className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2"
                                            type="submit" onClick={(e) => this.activateOpsMessenger()}>Ops Messenger</button>
                                </div>
                                : ""
                            }
                            {this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_FLIGHT_ARCHIVE, Config.getEnvironment() ) ?
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2"
                                        type="submit" onClick={(e) => this.activateFaReturnData()}>Flight Archive
                                    </button>
                                </div>
                                : ""
                            }
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            {this.state.user && this.authoriser.userHasAllowedRole( this.state.user, this.RESOURCE_GENDEC, Config.getEnvironment() ) ?
                                <div className="col-lg-6">
                                    <button className="btn btn-lg btn-primary btn-block text-uppercase font-weight-bold mb-2"
                                            type="submit" onClick={(e) => this.activateGendec()}>Gendec</button>
                                </div>
                                : ""
                            }
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}