import React from 'react';
import Predispatch from "../../components/predispatch.component";
import DateFormatter from "../../helpers/DateFormatter";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

class FlightkeysList extends Predispatch {

    async componentDidMount() {
        await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
    }

    isoDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateTimeFromIso( cell );
    }

    downloadDateFormat = ( cell, row, self ) => {
        return DateFormatter.getReadableDateFromFlightkeyDownloadTime( cell );
    }

    render() {
        return(
            <BootstrapTable
                // wrapperClasses="table-responsive"
                // responsive='md'
                // options={ options }
                data={ this.props.list }
                version='4'
                pagination={ false }
                search={ true }>
                <TableHeaderColumn isKey dataField='flightPlanId' dataSort={ true } hidden={ true }>Flightplan Id</TableHeaderColumn>
                {/*<TableHeaderColumn dataField='airline' dataSort={ true }>Airline</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='crew' width={ '10%' } dataSort={ true }>Crew</TableHeaderColumn>
                {/*<TableHeaderColumn dataField='flightplan' dataSort={ true }>Flightplan</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='release' width={ '10%' }>Release</TableHeaderColumn>
                <TableHeaderColumn dataField='time' width={ '16%' } dataFormat={ this.downloadDateFormat } formatExtraData={ this }>Download time</TableHeaderColumn>
                <TableHeaderColumn dataField='airport-weather' width={ '16%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>Airport weather</TableHeaderColumn>
                <TableHeaderColumn dataField='upper-air-data' width={ '16%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>Upper air data</TableHeaderColumn>
                <TableHeaderColumn dataField='notam-briefing' width={ '16%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>Notam</TableHeaderColumn>
                <TableHeaderColumn dataField='region-weather-briefing' width={ '16%' } dataSort={ true } dataFormat={ this.isoDateFormat } formatExtraData={ this }>Region weather</TableHeaderColumn>
            </BootstrapTable>

        )
    }
}

export default FlightkeysList;