import React from 'react';
import {Link} from "react-router-dom";
import Predispatch from "../../components/predispatch.component";
import FlightIds from '../services/FlightIds';

export default class Home extends Predispatch {

    constructor( props ) {
        super( props );
        this.flightIds = new FlightIds();
    }

    async componentDidMount() {
        let hasAccess = await this.validateResourceAccess( this.RESOURCE_FLIGHT_ARCHIVE );
        if( hasAccess ) {
            // Fill table with flights for today ( default behaviour, without extra params before submitting form )
            let flightIds = await this.flightIds.getFlightIds();
            if ( !flightIds ){
                this.toastMessages.toastError( 'Could not retrieve unmapped flights.', 'returndata-GET' );
            } else {
                let unmappedFlightIds = this.flightIds.filterUnmappedFlightIds( flightIds );
                this.setState( { flightIds: unmappedFlightIds } );
            }
        }
    }

    render() {
        let self = this;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="mt-5 mb-5">Flight Archive</h3>
                    </div>
                    <div className="col-lg-6">
                        <h4>Welcome!</h4>
                        <div className="row">
                            <Link to="/fa/search" className="nav-link">Search</Link>
                        </div>
                        <p className="mt-5">If you run into any problems, please send feedback to <a
                            href="mailto:tui_ofp_app_support@tui.com">tui_ofp_app_support@tui.com</a>.</p>
                    </div>
                    <div className="col-lg-6">
                        <h4>Missing IDPS connections</h4>
                        <div className="row">
                            { self.state.flightIds && self.state.flightIds.map( function( ids, key ){
                                return <Link to={"/fa/flight/" + ids.flightId} className="nav-link">{ids.flightId}</Link>
                            })}
                        </div>
                        <h4>Changelog</h4>
                        <div>
                            <h5>2020-10-13</h5>
                            <ul>
                                <li><i className="fa fa-star" aria-hidden="true"></i> Release of flight archive</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}